export class Leave {
    NAME: string
    ID: number
    CLIENT_ID: number
    EMPLOYEE_ID: number
    TYPE_ID: Number
    FROM_DATE: string
    TO_DATE: string 
    REASON: string
    HR_APPROVED_DATES: string
    HR_REMARK: string
    STATUS: string
    REJECT_REMARK: string
    HEAD_REMARK: string
    APPROVER_ID: number
    HEAD_STATUS: any
    LEAVE_TYPE_NAME: string
    MODE:any
    SESSION:string="1"
}