import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { leavePolicy } from '../../emp-leave-policy-maptable/leavepolicy';
// import { leavePolicy } from '../../leavePolicy';
// import { leavePolicy } from 'src/app/Models/leavePolicy';

@Component({
  selector: 'app-leave-policy-map',
  templateUrl: './leave-policy-map.component.html',
  styleUrls: ['./leave-policy-map.component.css']
})
export class LeavePolicyMapComponent implements OnInit {
  @Input() leaves = []
  @Input() mapClose: Function
  @Input() data: leavePolicy = new leavePolicy()
  @Input() LEAVE_POLICY_ID: any
  // LEAVE_POLICY_ID: any
  // PAYROLL_LEAVE_ID: any = []
  constructor(private api: ApiService, public message: NzNotificationService) { }

  ngOnInit() {
  }
  cancel() {
    this.mapClose()
  }

  save() {
    this.api.policyMapAddBulk(this.LEAVE_POLICY_ID, this.data.PAYROLL_LEAVE_ID).subscribe(data => {
      if (data['code'] == '200') {
        this.message.success('Leave policy map successfull','')
        this.mapClose()
      }
    })
  }

}
