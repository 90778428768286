import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { setHours } from 'date-fns';
import { ApiService } from 'src/app/Service/api.service';
import { CookieService } from 'ngx-cookie-service';
import { ExportService } from 'src/app/Service/export.service';

@Component({
  selector: 'app-emp-transaction-mapping',
  templateUrl: './emp-transaction-mapping.component.html',
  styleUrls: ['./emp-transaction-mapping.component.css']
})
export class EmpTransactionMappingComponent implements OnInit {
  @Input() drawerClose!: Function;
  @Input() dataTransaction = [];

  constructor(private datePipe: DatePipe, private api: ApiService, private message: NzNotificationService, private cookie: CookieService, private _exportService: ExportService) { }
  columns: string[][] = [["EMPLOYEE_NAME", "Employee Name"],
  ["TRANSACTION_DATE", "Transaction Date"],
 
  ["PAYROLL_LEAVE_TYPE_NAME", "Payroll Leave Type Name"], 
  ["TRANSACTION_TYPE", "Transaction Type"], 
  ["CR_COUNT", "CR Count"], 
  ["DR_COUNT", "DR Count"],
  ["DESCRIPTION", "Description"],
  ["CURRENT_BALANCE", "Current Balance"]]

  formTitle = "Employee Leave Transaction Report";

  filterClass: string = "filter-invisible";
  isFilterApplied: any = "default";
  dateFormat = "dd/MMM/yyyy";
  date: Date[] = [];
  startValue: any = new Date();
  endValue: any = new Date();
  endOpen = false;
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  sortKey: string = "ID";
  sortValue: string = "desc";
  searchText: string = "";
  filterQuery: string = "";
  loadingRecords = true;
  isSpinning = false;

  userId = Number(this.cookie.get('userId'));
  roleId = Number(this.cookie.get('roleId'));
  orgId = Number(this.cookie.get('orgId'));
  deptId = Number(this.cookie.get('deptId'));
  branchId = Number(this.cookie.get('branchId'));
  designationId = Number(this.cookie.get('designationId'));

  BRANCH_ID: any = [];
  DESIGNATION_ID: any;
  DEPARTMENT_ID: any;
  EMPLOYEE_ID: any = [];
  FROM_DATE: any;
  TO_DATE: any;
  

  data1 = [];
  data2 = [];
  data3 = [];
  data4 = [];

  sort(sort: any): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    if (this.sortValue == "descend") {
      this.sortValue = 'desc';
    } else {
      this.sortValue = 'asc'
    }
    console.log(this.sortValue + "kk");
    this.search(true);
  }

  ngOnInit() {
    this.date[0] = new Date();
    this.date[1] = new Date();
    this.search(true);
    this.getBranch();
    this.getEmployees();
    this.getDesignation();
    this.getDepartment();
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
  }

  branches = [];
  getBranch() {
    this.api.getAllBranch(0, 0, '', 'asc', 'AND STATUS=1 AND ORG_ID=' + this.orgId).subscribe((data1) => {
      console.log(data1);
      this.branches = data1['data'];
    },
      (err) => {
        console.log(err);
      }
    );
  }

  designations = [];
  getDesignation() {
    this.api.getAllDesignation(0, 0, '', 'asc', 'AND STATUS=1 AND ORG_ID=' + this.orgId).subscribe((data2) => {
      console.log(data2);
      this.designations = data2['data'];
    },
      (err) => {
        console.log(err);
      }
    );
  }

  departments = [];
  getDepartment() {
    this.api.getAllDepartments(0, 0, '', 'asc', 'AND STATUS=1 AND ORG_ID=' + this.orgId).subscribe((data3) => {
      console.log(data3);
      this.departments = data3['data'];
    },
      (err) => {
        console.log(err);
      }
    );
  }

  employees = [];
  getEmployees() {
    this.api.getAllEmployees(0, 0, '', 'asc', 'AND STATUS=1 AND ORG_ID=' + this.orgId).subscribe((data4) => {
      console.log(data4, 'data4');
      this.employees = data4['data'];
    },
      (err) => {
        console.log(err);
      }
    );
  }

  exportLoading: boolean = false;

  importInExcel() {
    this.search(true, true);
  }

  search(reset: boolean = false, exportInExcel: boolean = false) {
    var filter = ""
    if (reset) {
      this.pageIndex = 1;
    }
    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    }
    catch (error) {
      sort = "";
    }
    console.log("search text : " + this.searchText);
    var likeQuery = "";
    if (this.searchText != "") {
      likeQuery = this.searchText;
    }

    if (exportInExcel == false) {
      this.loadingRecords = true;

      this.FROM_DATE = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
      this.TO_DATE = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')

      this.api.getLeaveTransaction(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery + this.filterQuery, this.BRANCH_ID, this.EMPLOYEE_ID, this.DESIGNATION_ID, this.DEPARTMENT_ID, this.FROM_DATE, this.TO_DATE).subscribe(data => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.isFilterApplied = 'primary';
          this.totalRecords = data['count'];
          this.dataTransaction = data['data'];
          this.isSpinning = false;
          this.filterClass = 'filter-invisible';

        }

      }, err => {
        console.log(err);
      });

    }
    // dd
    else {
      this.exportLoading = true;
      this.api.getLeaveTransaction(0, 0, this.sortKey, sort, likeQuery + this.filterQuery, this.BRANCH_ID, this.EMPLOYEE_ID, this.DESIGNATION_ID, this.DEPARTMENT_ID, this.FROM_DATE, this.TO_DATE).subscribe
        (data => {
          if (data['code'] == 200) {
            this.exportLoading = false;
            this.dataTransaction = data['data'];
            this.convertInExcel();
          }
        },
          err => {
            if (err['ok'] == false)
              this.message.error("Server Not Found", "");
          });
    }
  }
  
  getDaysArray(start: any, end: any) {
    for (
      var arr = [], dt = new Date(start);
      dt <= new Date(end);
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(this.datePipe.transform(dt, 'yyyy-MM-dd'));
      this.dates.push(this.datePipe.transform(dt, 'yyyy-MM-dd'));
    }
    return arr;
  }

  timeDefaultValue = setHours(new Date(), 0);

  dates: any = [];
  today2 = new Date();

  onKeypressEvent() {
    document.getElementById("searchBtn").focus();
  }

  
  startDateChange() {
    var startDate = this.datePipe.transform(this.startValue, 'yyyy-MM-dd');
    var endDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    console.log(this.getDaysArray(startDate, endDate));
    console.log(this.dates);
  }

  moduleStartDateHandle(open: boolean) {
    // console.log(open);

    if (!open) {
      this.endOpen = true;

      // this.endValue = this.startValue;
    }
  }

  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";

    else
      this.filterClass = "filter-visible";
  }

  clearFilter() {
    this.filterClass = 'filter-invisible';
    this.BRANCH_ID = [];
    this.EMPLOYEE_ID = [];
    this.DESIGNATION_ID = null;
    this.DEPARTMENT_ID = null;
    this.startValue = new Date();
    this.endValue = new Date();
    this.FROM_DATE = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
    this.TO_DATE = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')
    this.dataTransaction=[];
    this.search();

  }

  applyFilter() {
    this.isFilterApplied = 'primary'
    this.loadingRecords = false;
    var sort: string;

    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    }
    catch (error) {
      sort = '';
    }
    this.startValue = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
    this.endValue = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')

    this.filterQuery = '';

    if (this.BRANCH_ID != undefined && this.BRANCH_ID.length > 0) {
      this.BRANCH_ID = this.BRANCH_ID
    }
    if (this.EMPLOYEE_ID != undefined && this.EMPLOYEE_ID.length > 0) {
      this.EMPLOYEE_ID = this.EMPLOYEE_ID
    }
    if (this.DESIGNATION_ID != undefined && this.DESIGNATION_ID.length > 0) {
      this.DESIGNATION_ID = this.DESIGNATION_ID
    }
    if (this.DEPARTMENT_ID != undefined && this.DEPARTMENT_ID.length > 0) {
      this.DEPARTMENT_ID = this.DEPARTMENT_ID
    }
    if (this.startValue != null) {
      this.FROM_DATE = this.startValue
    }

    if (this.endValue != null) {
      this.TO_DATE = this.endValue
    }


    this.search(true);

    this.filterClass = "filter-invisible";


  }

  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.dataTransaction.length; i++) {
      obj1['Employee Name'] = this.dataTransaction[i]['EMPLOYEE_NAME'];
     
      obj1['Payroll Leave Type Name'] = this.dataTransaction[i]['PAYROLL_LEAVE_TYPE_NAME'];
      obj1['Transaction Type'] = this.dataTransaction[i]['TRANSACTION_TYPE'];
      obj1['CR Count'] = this.dataTransaction[i]['CR_COUNT'];
      obj1['DR Count'] = this.dataTransaction[i]['DR_COUNT'];
      obj1['Balance'] = this.dataTransaction[i]['LEAVE_BALANCE_ADJUSTMENT_MASTER_ID'];
      obj1['Description'] = this.dataTransaction[i]['DESCRIPTION'];
      obj1['Current Balance'] = this.dataTransaction[i]['CURRENT_BALANCE'];


      arry1.push(Object.assign({}, obj1));
      if (i == this.dataTransaction.length - 1) {
        this._exportService.exportExcel(arry1, 'Employee Leave Mapping ' + this.datePipe.transform(new Date(), 'dd-MMM-yy'));
      }
    }
    this.search(true);
  }
}
