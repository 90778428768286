import { Component, HostListener, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { log } from 'console';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-chat-module',
  templateUrl: './chat-module.component.html',
  styleUrls: ['./chat-module.component.css']
})
export class ChatModuleComponent implements OnInit {
  @Input() drawerVisible1: boolean;
  @Input() drawerClose: Function;
  @Input() data: any;
  userId = this.cookie.get('userId');
  USERNAME = this.cookie.get('userName');
  roleId = Number(this.cookie.get('roleId'));
  constructor(private domSanitizer: DomSanitizer, private cookie: CookieService,) { }

  ngOnInit() { }

  transform() {  
      // var url = 'http://localhost:4200/chatlist/v?CLIENT_BOT_KEY=uvtech12345667hp&UIN=' + this.userId + '&NAME=' + this.USERNAME + '&DESCRIPTION=M&STATUS=A&ROLE_ID=' + this.roleId + "&LOGIN=1";
      var url = 'https://chat.uvtechsoft.com/chatlist/v?CLIENT_BOT_KEY=uvtech12345667hp&UIN=' + this.userId + '&NAME=' + this.USERNAME +'&DESCRIPTION=M&STATUS=A&ROLE_ID=' + this.roleId;
      return this.domSanitizer.bypassSecurityTrustResourceUrl(url);    
  }
}
