import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { NzNotificationService } from 'ng-zorro-antd';
import { EmployeeMaster } from 'src/app/Models/employeemaster';
import { ApiService } from 'src/app/Service/api.service';
import { CookieService } from 'ngx-cookie-service';
import { ExportService } from 'src/app/Service/export.service';

@Component({
  selector: 'app-attendancereport',
  templateUrl: './attendancereport.component.html',
  styleUrls: ['./attendancereport.component.css'],
  providers: [DatePipe]
})

export class AttendancereportComponent implements OnInit {
  dateFormat = "dd/MMM/yyyy";
  formTitle = "Attendance Report";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  excelDataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  isSpinning = false
  columns: string[][] = [["DATE", "Date"], ["IN_TIME", "In Time"], ["IN_LOCATION", "In Location"], ["IN_DISTANCE", "In Distance"], ["OUT_TIME", "Out Time"], ["OUT_LOCATION", "Out Location"], ["OUT_DISTANCE", "Out Distance"], ["WORKING_HOURS", "Working Hours"], ["STATUS", "Status"], ["DAYIN_REASON", "Day In Reason"], ["DAYOUT_REASON", "Day Out Reason"]];
  loadingFilterEmployees = true;
  filterClass: string = "filter-visible";
  initFilter = true;
  filterStatus = []
  filterEmployees: EmployeeMaster[];
  employeeFilter = [];
  filterStatusValue: string[] = [];
  date: Date[] = [];

  constructor(private api: ApiService, private datePipe: DatePipe, private message: NzNotificationService, private cookie: CookieService, private _exportService: ExportService) { }

  ngOnInit() {
    this.date[0] = new Date();
    this.date[1] = new Date();
    this.search(true);
    this.loadDataFilter();
    this.getBranch();
    this.getDepartment();
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  search(reset: boolean = false, exportInExcel: boolean = false) {
    this.filterQuery = "";

    if (reset) {
      this.pageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    if (this.searchText != "") {
      this.filterQuery += " AND (";

      likeQuery += " EMPLOYEE_NAME like ('%" + this.searchText + "%') OR";
      this.filterQuery += likeQuery.substring(0, likeQuery.length - 3) + ")";
    }

    var empFilterStr = "";
    if (this.employeeFilter.length > 0) {
      empFilterStr = " AND EMPLOYEE_ID IN (" + this.employeeFilter + ")";
    }

    var statusFilterStr = "";
    if (this.filterStatusValue.length > 0) {
      statusFilterStr = " AND STATUS IN (" + this.filterStatusValue + ")";
    }

    var dateFilter = "";
    if ((this.date != undefined) && (this.date.length != 0)) {
      dateFilter = " AND (DATE BETWEEN '" + this.datePipe.transform(this.date[0], 'yyyy-MM-dd 00:00:00') + "' AND '" + this.datePipe.transform(this.date[1], 'yyyy-MM-dd 23:59:59') + "')";
    }

    var branchFilterStr = "";
    if (this.BRANCH_ID.length > 0) {
      branchFilterStr = " AND BRANCH_ID IN (" + this.BRANCH_ID + ")";
    }

    var departmentFilterStr = "";
    if (this.DEPARTMENT_ID.length > 0) {
      departmentFilterStr = " AND DEPARTMENT_ID IN (" + this.DEPARTMENT_ID + ")";
    }

    if (exportInExcel) {
      this.exportLoading = true;

      this.api.getAttendanceReport(0, 0, this.sortKey, sort, this.filterQuery + empFilterStr + statusFilterStr + dateFilter + branchFilterStr + departmentFilterStr + " AND ACTIVE_STATUS=1 AND ORG_ID=" + this.orgId).subscribe(data => {
        if (data['code'] == 200) {
          this.exportLoading = false;
          this.excelDataList = data['data'];
          this.convertInExcel();
        }

      }, err => {
        if (err['ok'] == false)
          this.message.error("Server Not Found", "");
      });

    } else {
      this.loadingRecords = true;

      this.api.getAttendanceReport(this.pageIndex, this.pageSize, this.sortKey, sort, this.filterQuery + empFilterStr + statusFilterStr + dateFilter + branchFilterStr + departmentFilterStr + " AND ACTIVE_STATUS=1 AND ORG_ID=" + this.orgId).subscribe(data => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.dataList = data['data'];
        }

      }, err => {
        if (err['ok'] == false)
          this.message.error("Server Not Found", "");
      });
    }
  }

  onKeypressEvent() {
    document.getElementById('searchBtn').focus();
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  drawerClose(): void {
    this.search();
  }

  getLocation(value: string) {
    value = value.replace('_', ",")
    window.open("http://maps.google.com/maps?q=" + value, "_blank");
  }

  getName(STATUS) {
    if (STATUS == "P")
      return "Present";

    else if (STATUS == "L")
      return "Leave";

    else if (STATUS == "A")
      return "Absent";

    else if (STATUS == "HF")
      return "Halfday";

    else if (STATUS == "H")
      return "Holiday";

    else if (STATUS == "LM")
      return "Latemark";

    else
      return "";
  }

  loadDataFilter() {
    this.loadingFilterEmployees = true;

    this.api.getAllEmployees(0, 0, 'NAME', 'asc', '').subscribe(employees => {
      if (employees['code'] == 200) {
        this.filterEmployees = employees['data'];
        this.loadingFilterEmployees = false;
      }

    }, err => {
      console.log(err);
    });
  }

  clearFilter() {
    this.date = [];
    this.date[0] = new Date();
    this.date[1] = new Date();
    this.filterStatusValue = [];
    this.employeeFilter = [];
    this.BRANCH_ID = [];
    this.DEPARTMENT_ID = [];
    this.filterClass = "filter-invisible";
    this.isFilterApplied = "default";
    this.filterQuery = "";
    this.search(true);
  }

  applyFilter() {
    if ((this.date.length > 0) || (this.filterStatusValue.length > 0) || (this.employeeFilter.length > 0) || (this.BRANCH_ID.length > 0) || (this.DEPARTMENT_ID.length > 0))
      this.isFilterApplied = "primary";

    else
      this.isFilterApplied = "default";

    this.search(true);
    this.filterClass = "filter-invisible";
  }

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";

    else
      this.filterClass = "filter-visible";
  }

  getTimeInAM_PM(time) {
    return this.datePipe.transform(new Date(), "yyyy-MM-dd") + " " + time;
  }

  BRANCH_ID = [];
  DEPARTMENT_ID = [];
  branch = [];
  department = [];
  orgId = this.cookie.get('orgId');

  getBranch() {
    this.branch = [];

    this.api.getAllBranch(0, 0, 'NAME', 'asc', ' AND STATUS=1 AND ORG_ID=' + this.orgId).subscribe(data => {
      if (data['code'] == 200) {
        this.branch = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  getDepartment() {
    this.department = [];

    this.api.getAllDepartments(0, 0, 'NAME', 'asc', ' AND STATUS=1 AND ORG_ID=' + this.orgId).subscribe(data => {
      if (data['code'] == 200) {
        this.department = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  exportLoading: boolean = false;

  importInExcel() {
    this.search(true, true);
  }

  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();

    for (var i = 0; i < this.excelDataList.length; i++) {
      obj1['Date'] = this.datePipe.transform(this.excelDataList[i]['DATE'], 'dd-MM-yyyy');
      obj1['ID'] = this.excelDataList[i]['EMPLOYEE_ID'];
      obj1['Code'] = this.excelDataList[i]['EMPLOYEE_CODE'];
      obj1['Employee Name'] = this.excelDataList[i]['EMPLOYEE_NAME'];
      obj1['Branch Name'] = this.excelDataList[i]['BRANCH_NAME'];
      obj1['Dept. Name'] = this.excelDataList[i]['DEPARTMENT_NAME'];

      let inTime = this.datePipe.transform(new Date(), "yyyy-MM-dd") + " " + this.excelDataList[i]['IN_TIME'];
      obj1['In Time'] = this.datePipe.transform(inTime, 'hh:mm:ss a');
      obj1['In Location (Coordinates)'] = this.excelDataList[i]['IN_LOCATION'];
      obj1['In Distance'] = this.excelDataList[i]['IN_DISTANCE'];

      let outTime = this.datePipe.transform(new Date(), "yyyy-MM-dd") + " " + this.excelDataList[i]['OUT_TIME'];
      obj1['Out Time'] = this.datePipe.transform(outTime, 'hh:mm:ss a');
      obj1['Out Location (Coordinates)'] = this.excelDataList[i]['OUT_LOCATION'];
      obj1['Out Distance'] = this.excelDataList[i]['OUT_DISTANCE'];

      obj1['Working Hour(s)'] = this.excelDataList[i]['WORKING_HOURS'];
      obj1['Day In Reason'] = this.excelDataList[i]['DAYIN_REASON'];
      obj1['Day Out Reason'] = this.excelDataList[i]['DAYOUT_REASON'];

      arry1.push(Object.assign({}, obj1));

      if (i == this.excelDataList.length - 1) {
        this._exportService.exportExcel(arry1, 'Attendance Report ' + this.datePipe.transform(new Date(), 'dd-MMM-yy, hh mm ss a'));
      }
    }
  }
}
