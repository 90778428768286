import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NzDatePickerComponent, NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
// import { ApiService } from '../Service/api.service';
import { differenceInCalendarDays } from 'date-fns';
import { NgForm } from '@angular/forms';
import { Leave } from 'src/app/Models/Leave';
import { ApiService } from 'src/app/Service/api.service';
// import { rateMaster } from 'src/app/Models/IncentiveRateMaster';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
// import { leaveMaster } from '../LeaveMaster';
import { leaveadjustment } from 'src/app/Models/leaveadjustment';
import { leaveMaster } from '../../Leave Master/LeaveMaster';
// import { leaveadjustment } from 'src/app/Models/leaveadjustment';
// import {leaveadjustment} from 'src/app/Models/l'
// import { leaveMaster } from 'src/app/Models/LeaveMaster';
// import { Leave } from '../Models/Leave';
@Component({
  selector: 'app-leave-adjustment-master',
  templateUrl: './leave-adjustment-master.component.html',
  styleUrls: ['./leave-adjustment-master.component.css']
})
export class LeaveAdjustmentMasterComponent implements OnInit {

  @Input() drawerClose: Function
  @Input() dataList = []
  @Input() data: leaveadjustment;
  @Input() dataListtt = []

  @Input() drawerVisible: boolean;
  isSpinning = false;
  isSpinning11 = false;
  roles = [];
  model = [];
  leaveTypes = [];
  formTitle = "Leave Balance Adjustment Form"
  // dataList = [];
  @Input() MODELS = [];
  @Input() ROLES = [];
  @Input() SendData = [];



  Array1 = [];
  dataList1 = []
  disabled1 = true
  disabled2 = true
  leavedata = new leaveMaster();

  MODEL1: any
  FULES: any
  VARIENT: any
  TRANSMISSION: any

  @Input() selectedIndex

  pageIndex = 1;
  dataaa: number
  pageSize = 10;
  totalRecords = 1;
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  @Input() columns = []
  columns2: string[][] = [["EMPLOYEE_NAME", "Employees"]]

  orgId = this.cookie.get('orgId');
  finalData = []
  ROLES2 = []
  MODELS2 = []
  modeltype = []
  fueltype = []
  varienttype = []
  tranmission = []


  DESIGNATION_ID: any
  DEPARTMENT_ID: any
  BRANCH_ID: any
  @Input() filterbutton: boolean = true;
  @Input() tablehide: boolean;
  @Input() startbutton: boolean;

  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe, private cookie: CookieService) { }

  ngOnInit() {
    this.getModels();
  }

  filterClass: string = 'filter-visible';

  showFilter() {
    if (this.filterClass === 'filter-visible')
      this.filterClass = 'filter-invisible';
    else this.filterClass = 'filter-visible';

  }

  cancel() {
    this.drawerClose()
    this.dataList = []
    this.isSpinning = false;
    this.data.REMARK = ''
    this.filterbutton = true
    this.tablehide = true
    this.startbutton = false
    this.showinput = false
    // this.search();
  }


  clearFilter() {
    this.filterClass = 'filter-invisible';

    this.isFilterApplied = 'default';
    this.filterQuery = '';
    this.BRANCH_ID = []
    this.DEPARTMENT_ID = []
    this.DESIGNATION_ID = []
    this.dataList = [];
    this.search();
    // this.data.ALLOTMENT_TYPE_ID = null;

  }
  // isSpinning = false


  sort(sort: any): void {

    this.sortKey = sort.key;
    this.sortValue = sort.value;


    if (this.sortValue == "descend") {
      this.sortValue = 'desc';
    } else {
      this.sortValue = 'asc'
    }
    console.log(this.sortValue + "kk");
    this.search(true);
  }



  applyFilter() {


    if (

      this.BRANCH_ID != null ||
      this.DEPARTMENT_ID != null ||
      this.DESIGNATION_ID != null
    ) {
      this.isFilterApplied = "primary";
    }
    else this.isFilterApplied = "default";

    this.filterClass = "filter-invisible";

    this.search(true);
  }

  lastid: number

  EMPID = []
  allemps = []
  allempids = []

  search(reset: boolean = false) {
    var filter = ""
    this.filterQuery = ''
    if (reset) {
      this.pageIndex = 1;
    }

    // this.loadingRecords = true;
    var sort: string;

    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    console.log("search text : " + this.searchText);
    // console.log(sort);

    var likeQuery = "";

    if (this.searchText != "") {
      likeQuery = " AND ";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
    }

    var BranchFilter: any = "";
    if (this.BRANCH_ID != undefined && this.BRANCH_ID.length != 0) {
      BranchFilter = this.BRANCH_ID;
      this.filterQuery += " AND BRANCH_ID = " + this.BRANCH_ID;
    }

    var DepartmentFilter: any = "";
    if (this.DEPARTMENT_ID != undefined && this.DEPARTMENT_ID.length != 0) {
      DepartmentFilter = this.DEPARTMENT_ID;
      this.filterQuery += " AND DEPARTMENT_ID = " + this.DEPARTMENT_ID;
    }

    var DesignationFilter: any = "";
    if (this.DESIGNATION_ID != undefined && this.DESIGNATION_ID.length != 0) {
      DesignationFilter = this.DESIGNATION_ID;
      this.filterQuery += " AND DESIGNATION_ID = " + this.DESIGNATION_ID;
    }


    this.api.getAdjustmentData(0, 0, '', 0, '').subscribe(data1 => {
      console.log(data1['data']);
      if (data1['code'] == 200) {
        this.loadingRecords = false;
        this.totalRecords = data1['count'];
        this.lastid = data1['data'][0]['ID'];
        console.log(this.lastid);


        var adjustFilter: any = "";

        adjustFilter = this.lastid;
        this.filterQuery += " AND LEAVE_BALANCE_ADJUSTMENT_MASTER_ID = " + adjustFilter;

        this.api.getLeaveadjustmentDetails(0, 0, this.sortKey, this.sortValue, this.filterQuery).subscribe(data => {
          console.log(data['data']);
          if (data['code'] == 200) {
            this.loadingRecords = false;
            this.totalRecords = data['count'];
            this.dataList = data['data'];
            console.log(this.dataList);

            this.api.getLeaveData(0, 0, '', '', 'AND STATUS=1').subscribe(data2 => {
              if (data2['code'] == 200) {
                this.allemps = data2['data']
                this.columns = data2['data']


                // for (let h = 0; h < this.dataList.length; h++) {
                // this.dataList[0].split(",")
                var result: any = Object.keys(this.dataList[0]).map((key) => [(key), this.dataList[0][key]]);

                for (let j = 0; j < result.length; j++) {
                  //  if( this.dataList[i].EMPLOYEE_ID == this.allemps[j]['ID'])
                  //  this.dataList['NAME'] =(this.allemps[j]['NAME'])
                  var s = result[j][0].split('_')

                  for (let i = 0; i < this.allemps.length; i++) {
                    console.log(s[0]);
                    if (this.allemps[i]['ID'] == s[0].replace('LV', '')) {
                      var a = result[j][0].split('_')
                      if ('C' == a[1])
                        this.columns[i]['KEY1'] = result[j][0];
                      if ('S' == a[1])
                        this.columns[i]['KEY3'] = result[j][0];
                      if ('N' == a[1])
                        this.columns[i]['KEY2'] = result[j][0];
                      console.log(this.columns[i])
                      console.log(result[j][0])
                    }

                  }
                  if (j + 1 == result.length) {

                  }
                }
              }

            }, err => {
              if (err['ok'] == false)
                this.message.error("Server Not Found", "");
            });
          }

        }, err => {
          console.log(err);
        });


      }

    }, err => {
      console.log(err);
    });


  }


  loadRoles() {
    this.api.getAllRoles(0, 0, '', '', ' AND STATUS=1 ').subscribe(roles => {
      this.roles = roles['data'];
    }, err => {
      console.log(err);
    });
  }

  getModels() {
    this.model = [];

    this.api.getAllDesignation(0, 0, '', 'asc', 'AND STATUS=1').subscribe(data => {
      if (data['code'] == 200) {
        this.modeltype = data['data'];
      }
    }, err => {
      console.log(err);
    });

    this.api.getAllDepartments(0, 0, '', 'asc', 'AND STATUS=1').subscribe(data => {
      if (data['code'] == 200) {
        this.varienttype = data['data'];
      }
    }, err => {
      console.log(err);
    });

    this.api.getAllAttendanceBranch(0, 0, '', 'asc', 'AND STATUS=1').subscribe(data => {
      if (data['code'] == 200) {
        this.fueltype = data['data'];
      }
    }, err => {
      console.log(err);
    });


  }


  close(): void {
    this.drawerClose();

    this.isSpinning = false;
  }


  MappingData
  MODELeee = []

  Modeldata = []
  // SendData = []
  SendData1 = []



  finalDataa = []
  // dataListtt = []
  ROLES222 = []
  ROLESSS = []
  MODELSSSS = []

  MODELIDS = []
  Final = []
  va = []
  ids = []
  maindata = []
  sendthejson = []


  isVisible11 = false;


  save(dataListtt: any) {
    this.isSpinning = true;

    this.api.leaveAdjustmentaddbulk(dataListtt).subscribe(successCode => {
      if (successCode['code'] == "200") {
        this.message.success("Leave Adjustment Updated Successfully", "");
        this.isVisible11 = true

        // this.drawerClose()
      } else {
        this.message.error("Leave Adjustment Updation Failed", "");
        this.isSpinning = false;
      }
    });

  }

  todaydate: any
  data24
  showinput = false
  add() {
    this.isSpinning = true;
    this.todaydate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.data.REMARK = ''
    this.dataList = []
    this.columns = []
    this.tablehide = true
    this.startbutton = false
    this.filterbutton = true
    this.showinput = false
    this.api.createadjustment(this.cookie.get('userId'), this.todaydate, this.data.REMARK)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          this.showinput = true
          // this.message.success("Role Created Successfully...", "");  
          this.filterbutton = false
          this.tablehide = false
          this.startbutton = true
          this.isSpinning = false;
          this.message.info("Please Apply Filter...", "");
        }
        else {
          this.message.error("Start Adjustment Creation Failed...", "");
          this.isSpinning = false;
        }
      });
  }


  lastid1: any
  date111: any
  dataaaa: any
  ide: any
  isOk = false;
  IS_COMPLETED: any

  calculate() {

    // this.isSpinning11 = true; 
    if (this.data.ID) {


      this.IS_COMPLETED = true

      if (this.data.REMARK == "") {
        this.data.REMARK = ""
      }
      else {
        this.data.REMARK = this.data.REMARK
      }

      this.api.updateleaveAdjustment(this.data.ID, this.data.DATE, this.data.REMARK, this.data.USER_ID, this.IS_COMPLETED)
        .subscribe(successCode => {
          if (successCode['code'] == "200") {
            // this.message.success("data.REMARK updated Successfully...", "");
            this.isVisible11 = false;

            this.dataaaa = { LEAVE_BALANCE_ADJUSTMENT_MASTER_ID: this.data.ID, USER_ID: this.data.USER_ID }

            this.api.updateLeaveBalance(this.dataaaa).subscribe(successCode => {
              if (successCode['code'] == "200") {
                this.message.success("Leave Balance Updated Successfully", "");
                this.isVisible11 = false;
                this.isSpinning11 = false;
                this.showinput = false
                this.drawerClose()
                this.filterbutton = true
                this.tablehide = true
                this.startbutton = false
                this.isSpinning = false;
                this.dataList = []
                this.data.REMARK = ''
                console.log("okk")
              } else {
                this.message.error("Leave Balance Updation Failed", "");
                this.isSpinning = false;
              }
            });
          }
          else {
            this.message.error("Leave Balance Updation Failed...", "");
            this.isSpinning = false;
          }
        });







      console.log(this.lastid);

    } else {
      this.api.getAdjustmentData(0, 0, '', 0, '').subscribe(data1 => {
        console.log(data1['data']);
        if (data1['code'] == 200) {

          this.data24 = data1['data'][0]
          // this.totalRecords = data1['count'];
          this.lastid1 = data1['data'][0]['ID'];
          this.date111 = data1['data'][0]['DATE'];
          this.ide = data1['data'][0]['USER_ID'];
          this.IS_COMPLETED = true
          console.log(this.data24, "oksw");


          if (this.data.REMARK == "") {
            this.data.REMARK = ""
          }
          else {
            this.data.REMARK = this.data.REMARK
          }

          this.api.updateleaveAdjustment(this.lastid1, this.date111, this.data.REMARK, this.ide, this.IS_COMPLETED)
            .subscribe(successCode => {
              if (successCode['code'] == "200") {

                console.log("daa")


                this.dataaaa = { LEAVE_BALANCE_ADJUSTMENT_MASTER_ID: this.lastid1, USER_ID: this.ide }

                this.api.updateLeaveBalance(this.dataaaa).subscribe(successCode => {
                  if (successCode['code'] == "200") {
                    this.message.success("Leave Balance Updated Successfully", "");
                    this.isVisible11 = false;
                    this.isSpinning11 = false;
                    this.drawerClose()
                    this.filterbutton = true
                    this.tablehide = true
                    this.startbutton = false
                    this.isSpinning = false;
                    this.dataList = []
                    this.data.REMARK = ''
                  } else {
                    this.message.error("Leave Balance Updation Failed", "");
                    this.isSpinning = false;
                  }
                });
                this.isVisible11 = false;
              }
              else {
                this.message.error("Leave Balance Updation Failed...", "");
                this.isSpinning = false;
              }
            });




          console.log(this.lastid);
        }
      }, err => {
        console.log(err);
      });
    }


  }
  COUNT: any

  // onInvoiceAmtChange(amt: any) {
  //   // console.log(support_staff_id, 'support_staff_id');

  //   // this.dataList.forEach((item, index) => {
  //   //   if (item.SUPPORT_STAFF_AREA_ID == support_staff_id) {
  //   //     // console.log(item.SUPPORT_STAFF_AREA_ID, 'item.SUPPORT_STAFF_AREA_ID');
  //   //     this.dataList[index]['AMOUNT'] = amt;
  //   //     //  item['AMOUNT'] = item['AMOUNT']
  //   //   }
  //   // })
  //   for (let i = 0; i < this.dataList.length; i++) {
  //     for (let j = 0; j < this.allemps.length; j++) {
  //       this.dataList[i][this.allemps[j]['ID']] = this.dataList[i][this.allemps[j]['ID']]
  //     }
  //   }
  //   var result: any = Object.keys(this.dataList[0]).map((key) => [(key), this.dataList[0][key]]);

  //   // for (let j = 0; j < result.length; j++) {
  //   //   //  if( this.dataList[i].EMPLOYEE_ID == this.allemps[j]['ID'])
  //   //   //  this.dataList['NAME'] =(this.allemps[j]['NAME'])
  //   //   var s = result[j][0].split('_')

  //   //   for (let i = 0; i < this.allemps.length; i++) {
  //   //     console.log(s[0]);
  //   //     if (this.allemps[i]['ID'] == s[0].replace('LV', '')) {
  //   //       var a = result[j][0].split('_')
  //   //       // if ('C' == a[1])
  //   //       //   this.columns[i]['KEY1'] = amt
  //   //       // if ('S' == a[1])
  //   //       //   this.columns[i]['KEY3'] = amt
  //   //       if ('N' == a[1])
  //   //         this.columns[i]['KEY2'] = amt
  //   //       console.log(this.columns[i]['KEY2'])
  //   //     }

  //   //   }
  //   //   if (j + 1 == result.length) {

  //   //   }
  //   // }
  // }

  onInvoiceAmtChange(amt: any, colIndex: number) {

    for (let i = 0; i < this.dataList.length; i++) {
      this.dataList[i][this.columns[colIndex].KEY2] = amt;
    }


    console.log(this.dataList);
  }
  numberOnly(event: any) {
    const charCode = event.which ? event.which : event.keyCode;

    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57))
      return false;

    return true;
  }


}
