import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { NzNotificationService } from "ng-zorro-antd";
import { ApiService } from "src/app/Service/api.service";
import { ExportService } from "src/app/Service/export.service";

@Component({
  selector: "app-fresher-details-report",
  templateUrl: "./fresher-details-report.component.html",
  styleUrls: ["./fresher-details-report.component.css"],
})
export class FresherDetailsReportComponent implements OnInit {
  loadingRecords = false;
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  formTitle = "Fresher Detailes Report";
  isFilterApplied: any = "default";
  sortValue: string = "desc";
  sortKey: string = "";
  searchText: string = "";
  filterQuery: string = "";
  isSpinning = false;
  filterClass: string = "filter-invisible";
  // isFilterApplied: string = "default";
  quotationdate: any;

  models: any = [];
  branch: any = [];

  isOk = true;
  columns: string[][] = [
    ["NAME", "Name"],
    ["EMAIL_ID", "EMAIL_ID"],
    ["COLLEGE_NAME", "COLLEGE_NAME"],
    ["QUALIFICATION", "QUALIFICATION"],
  ];
  dataList1: any;

  START_DATE: any;
  END_DATE: any;
  MEMO_START_DATE: any;
  MEMO_END_DATE: any;
  MODEL_ID: any = [];
  BRANCH_ID: any = [];
  Value1: any;
  Value2: any;
  DATE: any = [];

  constructor(
    private api: ApiService,
    private _exportService: ExportService,
    private message: NzNotificationService,
    private datePipe: DatePipe
  ) {}

  ngOnInit() {
    // this.getAllModels();
    this.Value1 = new Date();
    this.Value2 = new Date();
    //  this.DATE=new Date()
    this.search(true);
  }

  isVisible1: boolean = false;
  drawerTitle1: string = "";
  members: any = [];
  FILTER_QUERY: any;

  // AddSchedule() {
  //   this.isVisible1 = true;
  //   var obj = {
  //     START_DATE: this.START_DATE,
  //     END_DATE: this.END_DATE,
  //     MODEL_ID: this.MODEL_ID,
  //   }
  //   this.FILTER_QUERY = JSON.stringify(obj);
  //   this.drawerTitle1 = "Manage Schedule"
  //   console.log("hi")
  //   this.api.getAllMembers(0, 0, '', '', ' AND REPORT_ID = 20').subscribe((data: any) => {
  //     this.members = data['data'];
  //   })
  // }
  drawerClose(): void {
    // this.search(false);
    this.isVisible1 = false;
  }
  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  onKeypressEvent(reset: any) {
    const element = window.document.getElementById("button");
    if (element != null) element.focus();
    // this.search(false);
  }

  exportLoading: boolean = false;

  importInExcel() {
    this.search(true, true);
  }
  search(reset: boolean, exportInExcel: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    console.log("search text : " + this.searchText);

    if (this.searchText != "") {
      likeQuery = " AND ";

      this.columns.forEach((column) => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2);
    }
    this.filterQuery = "";

    var filter = "";
    filter = this.filterQuery;

    var datefilter = "";

    if (this.Value1 != null && this.Value2 != null) {
      datefilter =
        " AND DATE(CREATED_MODIFIED_DATE) BETWEEN '" +
        this.datePipe.transform(this.Value1, "yyyy-MM-dd") +
        "' AND '" +
        this.datePipe.transform(this.Value2, "yyyy-MM-dd") +
        "' ";
    }
    if (exportInExcel == false) {
      this.loadingRecords = true;
      this.api
        .getFresherDetails(
          this.pageIndex,
          this.pageSize,
          this.sortKey,
          sort,
          likeQuery + datefilter
        )
        .subscribe(
          (data) => {
            console.log(data);
            this.loadingRecords = false;
            this.totalRecords = data["count"];
            this.dataList = data["data"];
            this.isSpinning = false;
            this.filterClass = "filter-invisible";
          },
          (err) => {
            console.log(err);
          }
        );
    } else {
      // this.exportLoading = false;
      this.api
        .getFresherDetails(
          0,
          0,
          this.sortKey,
          sort,
          this.filterQuery + likeQuery + datefilter
        )
        .subscribe(
          (data) => {
            if (data["code"] == 200) {
              // this.exportLoading = false;
              this.dataList1 = data["data"];
              this.convertInExcel();
            }
          },
          (err) => {
            if (err["ok"] == false) this.message.error("Server Not Found", "");
          }
        );
    }
  }
  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else this.filterClass = "filter-visible";
  }
  applyFilter() {
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    this.filterQuery = "";

    var filter = "";
    filter = this.filterQuery;
    var likeQuery = "";
    this.search(true);
    var likeQuery = "";

    if (this.searchText != "") {
      likeQuery = " AND";
      this.columns.forEach((column) => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2);
    }
  }

  clearFilter() {
    this.filterClass = "filter-invisible";
    this.isFilterApplied = "default";
    this.filterQuery = "";
    this.Value1 = null;
    this.Value2 = null;
    this.search(true);
  }

  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.dataList1.length; i++) {
      obj1["Name"] = this.dataList1[i]["NAME"];
      obj1["Mobile Number"] = this.dataList1[i]["MOBILE_NO"];
      obj1["E-mail ID"] = this.dataList1[i]["EMAIL_ID"];
      obj1["College Name"] = this.dataList1[i]["COLLEGE_NAME"];
      obj1["Qualification"] = this.dataList1[i]["QUALIFICATION"];

      if (this.dataList1[i]["IS_HAVE_EXPERIANCE"] == "1") {
        obj1["Is Experienced"] = "Yes";
      } else {
        obj1["Is Experienced"] = "No";
      }
      obj1["Exprience"] = this.dataList1[i]["EXPERIANCE"];
      obj1["Skills"] = this.dataList1[i]["SKILLS"];

      arry1.push(Object.assign({}, obj1));
      if (i == this.dataList1.length - 1) {
        this._exportService.exportExcel(
          arry1,
          "Fresher Details Report" +
            this.datePipe.transform(new Date(), "yyyy-MM-dd")
        );
      }
    }
  }
  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(false);
    console.log("SortKey", this.sortKey);
    console.log("SortValue", this.sortValue);
  }
}
