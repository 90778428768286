import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-leavetransactionsummaryreport-hrms',
  templateUrl: './leavetransactionsummaryreport-hrms.component.html',
  styleUrls: ['./leavetransactionsummaryreport-hrms.component.css']
})
export class LeavetransactionsummaryreportHRMSComponent implements OnInit {

  formTitle = "Leave Transaction Summary Report";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  fileName = 'Quotationdetailed.xlsx';
  dataList = [];
  exportLoading = false
  index = 0;
  exportToExcelDataList: any = []

  loadingRecords = false;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  dateQuery: string = "";
  isFilterApplied: string = "default";

  columns: string[][] = [["EMP_NAME", "Employee name"],
  ["PL_1_OP", "Opening"],
  ["PL_1_CR", "CL"],
  ["PL_1_DR", "Medical	Total"],
  ["PL_1_CL", "Medical	Total"],

  ["CL_3_OP", "Casual	Total"],
  ["CL_3_CR", "Casual	Total"],
  ["CL_3_DR", "Casual	Total"],
  ["CL_3_CL", "Casual	Total"],

  ["SL_2_OP", "Sick	Total"],
  ["SL_2_CR", "Sick	Total"],
  ["SL_2_DR", "Sick	Total"],
  ["SL_2_CL", "Sick	Total"],

  ["CO_4_OP", "CompOff	Total"],
  ["CO_4_CR", "CompOff	Total"],
  ["CO_4_DR", "CompOff	Total"],
  ["CO_4_CL", "CompOff	Total"],

  ["LWP_5_OP", "LWP	Total"],
  ["LWP_5_CR", "LWP	Total"],
  ["LWP_5_DR", "LWP	Total"],
  ["LWP_5_CL", "LWP	Total"],
  ]

  isSpinning = false
  filterClass: string = "filter-invisible";
  SALES_EXECUTIVE_ID: any = []
  Value1: any
  Value2: any
  selectedDate: Date[] = []
  dateFormat = 'dd/MM/yyyy';
  MEMO_DATE: any
  data1 = [];
  INVOICE_DATE: any
  MODEL_ID: any = []
  DATE: any = []

  month = new Date().getMonth().toString() + (new Date().getMonth() + 1).toString()
  year = new Date().getFullYear()
  MONTH: any = this.month;
  MONTH1: any = new Date()
  YEAR: any = this.year
  monthFormat = "MMM-yyyy";
  transaction: any = []

  MEMO_STATUS: any[];
  constructor(private api: ApiService, private datePipe: DatePipe, private _exportService: ExportService, private message: NzNotificationService) { }

  ngOnInit() {
    this.search(true);
  }

  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    this.search(reset);
  }

  // Basic Methods
  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    // this.search(true);
  }

  isVisible: boolean = false

  handleOk(): void {
    console.log('Button ok clicked!');
    this.isVisible = false;
  }

  handleCancel(): void {
    console.log('Button cancel clicked!');
    this.isVisible = false;
  }

  openPrint() {
    this.getLeaveTransaction()
    this.isVisible = true;
  }

  LIKE_STRING: any = []
  transactionData: any = []

  search(reset: boolean = false, exportInExcel: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    console.log("search text : " + this.searchText);

    this.LIKE_STRING = this.searchText;

    if (this.MONTH1 != undefined) {
      this.MONTH = this.datePipe.transform(this.MONTH1, 'MM')
    }

    if (this.MONTH1 != undefined) {
      this.YEAR = this.datePipe.transform(this.MONTH1, 'yyyy')
    }

    this.loadingRecords = true
    this.api.leaveTransactionSummaryReporthrms(this.pageIndex, this.pageSize, this.sortKey, sort, this.LIKE_STRING, this.MONTH, this.YEAR).subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.transaction = data['data'];
        this.filterClass = "filter-invisible";
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  getLeaveTransaction() {
    this.exportLoading = true;
    this.api.leaveTransactionSummaryReporthrms(0, 0, this.sortKey, this.sortValue, this.LIKE_STRING, this.MONTH, this.YEAR).subscribe(data => {
      if (data['code'] == 200) {
        this.exportLoading = false;
        this.transactionData = data['data'];
      }

    }, err => {
      if (err['ok'] == false)
        console.log("Server Not Found", "");
    });
  }


  clearFilter() {
    this.filterClass = "filter-invisible";
    this.MONTH1 = new Date();
    this.search()
    this.isFilterApplied = "primary";
  }

  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";
  }

  Value: any

  applyFilter(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    this.isFilterApplied = 'primary'
    this.filterClass = "filter-invisible";

    this.loadingRecords = false;

    if (this.MONTH1 != undefined) {
      this.MONTH = this.datePipe.transform(this.MONTH1, 'MM')
    }

    if (this.MONTH1 != undefined) {
      this.YEAR = this.datePipe.transform(this.MONTH1, 'yyyy')
    }

    this.search(true);
    this.isFilterApplied = 'primary'

  }

}
