import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { NzNotificationService } from 'ng-zorro-antd';
import { ExpensesHeadMaster } from 'src/app/Models/expenses-head-master';
import { CustomerMaster } from 'src/app/Models/customer-master';
import { EmployeeMaster } from 'src/app/Models/employeemaster';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-expensereport',
  templateUrl: './expensereport.component.html',
  styleUrls: ['./expensereport.component.css'],
  providers: [DatePipe]
})

export class ExpensereportComponent implements OnInit {
  dateFormat = "dd/MMM/yyyy"
  formTitle = "Expenses Report";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  exportToExcelDataList = [];
  loadingRecords = true;
  exportToExcelLoading = false;
  sortValue: string = "desc";
  sortKey: string = "VOUCHER_NO";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [["VOUCHER_NO", "Voucher No."], ["DATE", "Expense Date"], ["CLAIM_DATE", "Claim Date"], ["EXPENSE_HEAD_NAME", "Head"], ["CUSTOMER_NAME", "Customer"], ["PARTY_NAME", "Party"], ["AMOUNT", "Amount"], ["DESCRIPTION", "Description"], ["PEOPLES_NAMES", "People With"], ["ATTACHMENT", "View Bill"], ["HEAD_STATUS", "Head Status"], ["HEAD_TIME", "Head Time"], ["HEAD_REMARK", "Head Remark"], ["AC_STATUS", "Accountant Status"], ["AC_REMARK", "Accountant Remark"], ["AC_TIME", "Accountant Time"], ["STATUS", "Main Status"]];
  loadingFilterEmployees = true;
  loadingFilterExpenseHeads = true;
  loadingFilterCustomers = true;
  filterClass: string = "filter-visible";
  initFilter = true;
  filterStatus
  filterEmployees: [];
  filterExpenseHeads: ExpensesHeadMaster[];
  filterCustomers: CustomerMaster[];
  employeeFilter = [];
  filterStatusValue: string[] = [];
  expanseHeadFilter = [];
  customerFilter = [];
  filterExpenseHeadValue: string[] = [];
  filterCustomerValue: string[] = [];
  date: Date[] = [];
  isSpinning = false;
  excelDataList = [];

  constructor(private api: ApiService, private datePipe: DatePipe, private message: NzNotificationService, private _exportService: ExportService, private cookie: CookieService) { }

  ngOnInit() {
    this.date[0] = new Date();
    this.date[1] = new Date();
    this.loadDataFilter();
    this.search(true);
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  orgId = this.cookie.get('orgId');

  search(reset: boolean = false, exportToExcel: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    if (this.searchText != "") {
      this.filterQuery += " AND (";
      var likeQuery = " ";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like ('%" + this.searchText + "%') OR";
      });

      this.filterQuery += likeQuery.substring(0, likeQuery.length - 3) + ")";
    }

    var empFilterStr = "";
    if (this.employeeFilter.length > 0) {
      empFilterStr = " AND EMPLOYEE_ID IN (" + this.employeeFilter + ")";
    }

    var statusFilterStr = "";
    if (this.filterStatusValue.length > 0) {
      statusFilterStr = " AND STATUS IN (" + this.filterStatusValue + ")";
    }

    var expensesHeadFilterStr = "";
    if (this.filterExpenseHeadValue.length > 0) {
      expensesHeadFilterStr = " AND HEAD_ID IN (" + this.filterExpenseHeadValue + ")";
    }

    var customerFilterStr = "";
    if (this.filterCustomerValue.length > 0) {
      customerFilterStr = " AND CUST_ID IN (" + this.filterCustomerValue + ")";
    }

    var dateFilter = "";
    if ((this.date != undefined) && (this.date.length != 0)) {
      dateFilter = " AND (CLAIM_DATE BETWEEN '" + this.datePipe.transform(this.date[0], 'yyyy-MM-dd 00:00:00') + "' AND '" + this.datePipe.transform(this.date[1], 'yyyy-MM-dd 23:59:59') + "')";
    }

    if (exportToExcel == false) {
      this.loadingRecords = true;

      this.api.getExpenseReport(this.pageIndex, this.pageSize, this.sortKey, sort, this.filterQuery + empFilterStr + statusFilterStr + expensesHeadFilterStr + customerFilterStr + dateFilter + " AND ACTIVE_STATUS=1 AND ORG_ID=" + this.orgId).subscribe(data => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.dataList = data['data'];
        }

      }, err => {
        if (err['ok'] == false)
          this.message.error("Server Not Found", "");
      });

    } else {
      this.exportToExcelLoading = true;

      this.api.getExpenseReport(0, 0, this.sortKey, sort, this.filterQuery + empFilterStr + statusFilterStr + expensesHeadFilterStr + customerFilterStr + dateFilter + " AND ACTIVE_STATUS=1 AND ORG_ID=" + this.orgId).subscribe(data => {
        if (data['code'] == 200) {
          this.exportToExcelLoading = false;
          this.exportToExcelDataList = data['data'];
          this.convertInExcel();
        }

      }, err => {
        if (err['ok'] == false)
          this.message.error("Server Not Found", "");
      });

    }
  }

  onKeypressEvent(reset: boolean = false) {
    document.getElementById('button').focus();
    this.search(true);
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  drawerClose(): void {
    this.search();
  }

  loadDataFilter() {
    this.loadingFilterEmployees = true;

    this.api.getAllEmployees(0, 0, 'NAME', 'asc', '').subscribe(employees => {
      if (employees['code'] == 200) {
        this.filterEmployees = employees['data'];
        this.loadingFilterEmployees = false;
      }

    }, err => {
      console.log(err);
    });

    this.api.getAllExpenseHead(0, 0, 'NAME', 'asc', '').subscribe(expenseHeads => {
      if (expenseHeads['code'] == 200) {
        this.filterExpenseHeads = expenseHeads['data'];
        this.loadingFilterExpenseHeads = false;
      }

    }, err => {
      console.log(err);
    });

    this.api.getAllCustomers(0, 0, 'NAME', 'asc', '').subscribe(customers => {
      if (customers['code'] == 200) {
        this.filterCustomers = customers['data'];
        this.loadingFilterCustomers = false;
      }

    }, err => {
      console.log(err);
    });
  }



  imgurl = this.api.retriveimgUrl
  getAttachment(value) {
    window.open(this.api.retriveimgUrl + "expenses/" + value);
  }

  getName(STATUS) {
    if (STATUS == "P")
      return "Pending";

    else if (STATUS == "A")
      return "Approved";

    else if (STATUS == "O")
      return "On Hold";

    else if (STATUS == "HA")
      return "Head Approved";

    else if (STATUS == "HR")
      return "Head Rejected";

    else if (STATUS == "R")
      return "Rejected";

    else
      return "";
  }

  clearFilter() {
    this.date = [];
    this.date[0] = new Date();
    this.date[1] = new Date();
    this.filterStatusValue = [];
    this.employeeFilter = [];
    this.filterCustomerValue = [];
    this.filterExpenseHeadValue = [];
    this.filterClass = "filter-invisible";
    this.isFilterApplied = "default";
    this.filterQuery = "";
    this.search(true);
  }

  applyFilter() {
    if ((this.date.length > 0) || (this.filterStatusValue.length > 0) || (this.employeeFilter.length > 0) || (this.filterCustomerValue.length > 0) || (this.filterExpenseHeadValue.length > 0))
      this.isFilterApplied = "primary";

    else
      this.isFilterApplied = "default";

    this.search(true);
    this.filterClass = "filter-invisible";
  }

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";

    else
      this.filterClass = "filter-visible";
  }

  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();

    for (var i = 0; i < this.exportToExcelDataList.length; i++) {
      obj1['Main Status'] = this.getName(this.exportToExcelDataList[i]['STATUS']);
      obj1['Voucher No.'] = this.exportToExcelDataList[i]['VOUCHER_NO'];
      obj1['Employee Name'] = this.exportToExcelDataList[i]['EMPLOYEE_NAME'];
      obj1['Expense Date'] = this.datePipe.transform(this.exportToExcelDataList[i]['CLAIM_DATE'], 'dd-MM-yyyy');
      obj1['Claim Date'] = this.datePipe.transform(this.exportToExcelDataList[i]['DATE'], 'dd-MM-yyyy');
      obj1['Expense Head'] = this.exportToExcelDataList[i]['EXPENSE_HEAD_NAME'];
      obj1['Location'] = this.exportToExcelDataList[i]['CUST_NAME'];
      obj1['Party Name'] = this.exportToExcelDataList[i]['PARTY_NAME'];
      obj1['Amount'] = this.exportToExcelDataList[i]['AMOUNT'];
      obj1['Description'] = this.exportToExcelDataList[i]['DESCRIPTION'];
      obj1['People With'] = this.exportToExcelDataList[i]['PEOPLES_NAMES'];
      // obj1['View Bill'] = this.exportToExcelDataList[i]{{imgurl}}expenses/{{data.ATTACHMENT}}['PEOPLES_NAMES'];
      if (this.exportToExcelDataList[i]['ATTACHMENT'] != null && this.exportToExcelDataList[i]['ATTACHMENT'] != "" && this.exportToExcelDataList[i]['ATTACHMENT'] != undefined) {
        obj1['View Bill'] = `${this.imgurl}expenses/${this.exportToExcelDataList[i]['ATTACHMENT']}`;

      }

      else {
        obj1['View Bill'] = "No Bill";

      }
      obj1['Head Status'] = this.getName(this.exportToExcelDataList[i]['HEAD_STATUS']);
      obj1['Head Time'] = this.datePipe.transform(this.exportToExcelDataList[i]['HEAD_TIME'], 'dd-MM-yyyy, hh:mm:ss a');
      obj1['Head Remark'] = this.exportToExcelDataList[i]['HEAD_REMARK'];
      obj1['Accountant Status'] = this.getName(this.exportToExcelDataList[i]['AC_STATUS']);
      obj1['Accountant Time'] = this.datePipe.transform(this.exportToExcelDataList[i]['AC_TIME'], 'dd-MM-yyyy, hh:mm:ss a');
      obj1['Accountant Remark'] = this.exportToExcelDataList[i]['AC_REMARK'];

      arry1.push(Object.assign({}, obj1));

      if (i == this.exportToExcelDataList.length - 1) {
        this._exportService.exportExcel(arry1, 'Expenses Report ' + this.datePipe.transform(new Date(), 'dd-MMM-yy, hh mm ss a'));
      }
    }
  }

  // convertInExcel() {
  //   let arry1 = [];
  //   let obj1: any = {};

  //   for (let i = 0; i < this.exportToExcelDataList.length; i++) {
  //     obj1['Main Status'] = this.getName(this.exportToExcelDataList[i]['STATUS']);
  //     obj1['Voucher No.'] = this.exportToExcelDataList[i]['VOUCHER_NO'];
  //     obj1['Employee Name'] = this.exportToExcelDataList[i]['EMPLOYEE_NAME'];
  //     obj1['Expense Date'] = this.datePipe.transform(this.exportToExcelDataList[i]['CLAIM_DATE'], 'dd-MM-yyyy');
  //     obj1['Claim Date'] = this.datePipe.transform(this.exportToExcelDataList[i]['DATE'], 'dd-MM-yyyy');
  //     obj1['Expense Head'] = this.exportToExcelDataList[i]['EXPENSE_HEAD_NAME'];
  //     obj1['Location'] = this.exportToExcelDataList[i]['CUST_NAME'];
  //     obj1['Party Name'] = this.exportToExcelDataList[i]['PARTY_NAME'];
  //     obj1['Amount'] = this.exportToExcelDataList[i]['AMOUNT'];
  //     obj1['Description'] = this.exportToExcelDataList[i]['DESCRIPTION'];
  //     obj1['People With'] = this.exportToExcelDataList[i]['PEOPLES_NAMES'];
  //     obj1['View Bill'] = (this.exportToExcelDataList[i]['ATTACHMENT'] != null && this.exportToExcelDataList[i]['ATTACHMENT'] != "" && this.exportToExcelDataList[i]['ATTACHMENT'] != undefined) ?
  //       `=HYPERLINK("${this.imgurl}expenses/${this.exportToExcelDataList[i]['ATTACHMENT']}", "Download")` :
  //       "No Bill";
  //     obj1['Head Status'] = this.getName(this.exportToExcelDataList[i]['HEAD_STATUS']);
  //     obj1['Head Time'] = this.datePipe.transform(this.exportToExcelDataList[i]['HEAD_TIME'], 'dd-MM-yyyy, hh:mm:ss a');
  //     obj1['Head Remark'] = this.exportToExcelDataList[i]['HEAD_REMARK'];
  //     obj1['Accountant Status'] = this.getName(this.exportToExcelDataList[i]['AC_STATUS']);
  //     obj1['Accountant Time'] = this.datePipe.transform(this.exportToExcelDataList[i]['AC_TIME'], 'dd-MM-yyyy, hh:mm:ss a');
  //     obj1['Accountant Remark'] = this.exportToExcelDataList[i]['AC_REMARK'];

  //     arry1.push(Object.assign({}, obj1));

  //     if (i == this.exportToExcelDataList.length - 1) {
  //       this._exportService.exportExcel1(arry1, 'Expenses Report ' + this.datePipe.transform(new Date(), 'dd-MMM-yy, hh mm ss a'));
  //     }

  //   }

  //   // Export data to Excel
  //   //   this._exportService.convertInExcel() {
  //   //     let arry1 = [];
  //   //     let obj1: any = {};

  //   //     for (let i = 0; i < this.exportToExcelDataList.length; i++) {
  //   //         obj1['Main Status'] = this.getName(this.exportToExcelDataList[i]['STATUS']);
  //   //         obj1['Voucher No.'] = this.exportToExcelDataList[i]['VOUCHER_NO'];
  //   //         obj1['Employee Name'] = this.exportToExcelDataList[i]['EMPLOYEE_NAME'];
  //   //         obj1['Expense Date'] = this.datePipe.transform(this.exportToExcelDataList[i]['CLAIM_DATE'], 'dd-MM-yyyy');
  //   //         obj1['Claim Date'] = this.datePipe.transform(this.exportToExcelDataList[i]['DATE'], 'dd-MM-yyyy');
  //   //         obj1['Expense Head'] = this.exportToExcelDataList[i]['EXPENSE_HEAD_NAME'];
  //   //         obj1['Location'] = this.exportToExcelDataList[i]['CUST_NAME'];
  //   //         obj1['Party Name'] = this.exportToExcelDataList[i]['PARTY_NAME'];
  //   //         obj1['Amount'] = this.exportToExcelDataList[i]['AMOUNT'];
  //   //         obj1['Description'] = this.exportToExcelDataList[i]['DESCRIPTION'];
  //   //         obj1['People With'] = this.exportToExcelDataList[i]['PEOPLES_NAMES'];
  //   //         obj1['View Bill'] = (this.exportToExcelDataList[i]['ATTACHMENT'] != null && this.exportToExcelDataList[i]['ATTACHMENT'] != "" && this.exportToExcelDataList[i]['ATTACHMENT'] != undefined) ?
  //   //             // `${this.imgurl}expenses/${this.exportToExcelDataList[i]['ATTACHMENT']}` :
  //   //             `=HYPERLINK("${this.imgurl}expenses/${this.exportToExcelDataList[i]['ATTACHMENT']}", "Download")`:
  //   //             "No Bill";
  //   //         obj1['Head Status'] = this.getName(this.exportToExcelDataList[i]['HEAD_STATUS']);
  //   //         obj1['Head Time'] = this.datePipe.transform(this.exportToExcelDataList[i]['HEAD_TIME'], 'dd-MM-yyyy, hh:mm:ss a');
  //   //         obj1['Head Remark'] = this.exportToExcelDataList[i]['HEAD_REMARK'];
  //   //         obj1['Accountant Status'] = this.getName(this.exportToExcelDataList[i]['AC_STATUS']);
  //   //         obj1['Accountant Time'] = this.datePipe.transform(this.exportToExcelDataList[i]['AC_TIME'], 'dd-MM-yyyy, hh:mm:ss a');
  //   //         obj1['Accountant Remark'] = this.exportToExcelDataList[i]['AC_REMARK'];

  //   //         arry1.push(Object.assign({}, obj1));
  //   //     }

  //   //     // Export data to Excel
  //   //     this._exportService.exportExcelWithHyperlink(
  //   //         arry1,
  //   //         'Expenses Report ' + this.datePipe.transform(new Date(), 'dd-MMM-yy, hh mm ss a'),
  //   //         'View Bill'
  //   //     );
  //   // }
  //   // (
  //   //       arry1,
  //   //       'Expenses Report ' + this.datePipe.transform(new Date(), 'dd-MMM-yy, hh mm ss a'),
  //   //       'View Bill'
  //   //   );
  // }

}
