export class LeaveEncashment {
    ID: number;

    EMP_NAME: string;
    STATUS:any;
    APPLIED_DATETIME:any;
    EMP_ID:any
    EMP_REMARK:any
    PAYROLL_LEAVE_NAME:any;
    DETAILS: any = [];
    IS_FROM_ADMIN:any
    CHECKED_BY_ID:any;
    CHECKED_REMARK:any
    CHECKED_DATETIME:any;
    YEAR_ID:any 
}