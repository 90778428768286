import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { NzNotificationService } from "ng-zorro-antd";
import { CookieService } from "ngx-cookie-service";
// import { LeaveEncashment } from "src/app/Models/LeaveEncashment";
import { EmployeeMaster } from "src/app/Models/employeemaster";
import { LeaveEncashment } from "src/app/Models/LeaveEncashment (1)";
import { ApiService } from "src/app/Service/api.service";
import { ExportService } from "src/app/Service/export.service";

@Component({
  selector: "app-leave-encashment-list",
  templateUrl: "./leave-encashment-list.component.html",
  styleUrls: ["./leave-encashment-list.component.css"],
})
export class LeaveEncashmentListComponent implements OnInit {
  formTitle = "My Encashment Applications";
  sortKey: string = "ID";
  sortValue: string = "desc";
  searchText: string = "";
  filterClass: string = "filter-visible";
  dateFormat='dd-MM-yy'
  // roleId = this.cookie.get('roleId')

  drawerVisible: boolean;
  drawerTitle: string;
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  filterQuery: string = "";
  date: Date[] = [];
  DEPARTMENT = "AL";
  roleId = Number(this.cookie.get("roleId"));
  userId = this.cookie.get("userId");
  loadingRecords = true;
  loadingFilterEmployees = true;
  isFilterApplied: string = "default";
  columns: string[][] = [
    ["STATUS", "Status"],
    ["EMP_NAME", "Name"],
    ["APPLIED_DATETIME", "applied Date"],
    ["CHECKED_DATETIME", "checked Date"],
    ["EMP_REMARK", "remark."],
    ["CHECKED_REMARK", "CHECKED_REMARK."],

  ];
  employees = [];
  filterEmployees: EmployeeMaster[];
  employee: any;
  isSpinning = false;
  demoValue = 10;
  drawerData: LeaveEncashment = new LeaveEncashment();
  Y_ID: any;
  user: any;

  constructor(
    private api: ApiService,
    private datePipe: DatePipe,
    private message: NzNotificationService,
    private _exportService: ExportService,
    private cookie: CookieService
  ) {}

  ngOnInit() {
    this.search();
    this.loadDataFilter();
  }

  changeRadioButton(Status) {
    this.STATUS = Status;
    this.search(true);
  }

  onKeypressEvent() {
    document.getElementById("searchBtn").focus();
  }

  keyup(event: any) {
    this.search();
  }

  applyFilter() {
    if (this.date.length > 0) {
      this.isFilterApplied = "primary";
    } else {
      this.isFilterApplied = "default";
    }
    this.search(true);
    this.filterClass = "filter-invisible";
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else this.filterClass = "filter-visible";
  }

  sort(sort: any): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    if (this.sortValue == "descend") {
      this.sortValue = "desc";
    } else {
      this.sortValue = "asc";
    }
    console.log(this.sortValue + "kk");
    this.search(true);
  }

  clearFilter() {
    this.date = [];
    // this.date[0] = new Date();
    // this.date[1] = new Date();
    // this.STATUS=''
    this.empFilterStr=""
    // this.employeeFilter = "";
    this.filterClass = "filter-invisible";
    this.isFilterApplied = "default";
    this.filterQuery = "";
    this.employeeFilter = null;
    this.search(true);
  }

  emp_ID;
  emp_id;
  sRadio = false;
  vDetails = false;
  view(data: LeaveEncashment): void {
    console.log(data);
    this.drawerTitle = "My Leave Encashmant Application";
    this.drawerData = Object.assign({}, data);
    this.empId = this.drawerData.ID;
    this.Date = this.drawerData.APPLIED_DATETIME;

    this.api
      .getLeaveEncashmentDetails(
        0,
        0,
        "",
        "",
        " AND LEAVE_ENCASH_REQ_ID =" + this.empId
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.employee = data["data"];
          }
        },
        (err) => {
          console.log(err);
        }
      );

    if (this.empId != null) {
      this.api
        .getLeaveEncashmentDetails(
          0,
          0,
          "",
          "",
          " AND LEAVE_ENCASH_REQ_ID =" + this.empId
        )
        .subscribe(
          (data) => {
            if (data["code"] == 200) {
              this.drawerData.DETAILS = data["data"];
              this.sRadio = false;
              this.vDetails = true;
              this.drawerVisible = true;
            }
          },
          (err) => {
            console.log(err);
          }
        );
    } else {
      this.drawerData.ID = null;
      // this.data.OFFICE_NAME = '';
    }
    // this.sRadio = false;
    // this.vDetails = true;
    // this.drawerVisible = true;
  }
  Date;
  ApproveOrReject(data: LeaveEncashment): void {
    console.log(data);
    this.drawerTitle = "My Leave Encashmant Application";
    this.drawerData = Object.assign({}, data);
    this.Date = this.drawerData.APPLIED_DATETIME;

    this.empId = this.drawerData.ID;

    if (this.empId != null) {
      this.api
        .getLeaveEncashmentDetails(
          0,
          0,
          "",
          "",
          " AND LEAVE_ENCASH_REQ_ID =" + this.empId
        )
        .subscribe(
          (data) => {
            if (data["code"] == 200) {
              this.drawerData.DETAILS = data["data"];
              this.sRadio = true;
              this.drawerVisible = true;
            }
          },
          (err) => {
            console.log(err);
          }
        );
    } else {
      this.drawerData.ID = null;
      // this.data.OFFICE_NAME = '';
    }
  }

  empId: any;
  STATUS = "P";
  employeeFilter:any;
  

  loadDataFilter() {
    this.loadingFilterEmployees = true;

    this.api.getAllEmployees(0, 0, "NAME", "asc", "").subscribe(
      (employees) => {
        if (employees["code"] == 200) {
          this.filterEmployees = employees["data"];
          this.loadingFilterEmployees = false;
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  add() {
    this.sRadio = false;
    this.vDetails = false;
    this.drawerTitle = "New Leave Encashmant Application";
    this.drawerData = new LeaveEncashment();
    this.Date = new Date();

    if(this.roleId==3){
      this.api
      .getLeaveEncashment(this.userId, 0, 0, "", "", " AND ID =" + this.userId)
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.emp_id = this.userId;

            this.employee = data["data"];
            this.drawerData.DETAILS = [];

            for (let index = 0; index < this.employee.length; index++) {
              this.drawerData.DETAILS.push({
                ID: this.employee[index]["ID"],
                LEAVE_TYPE_ID: this.employee[index]["PAYROLL_LEAVE_ID"],
                APPLIED_BALANCE: 0,
                AVAILABLE_BALANCE: this.employee[index]["BALANCE"],
                BALANCE: this.employee[index]["BALANCE"],
                LEAVE_TYPE_NAME:
                  this.employee[index]["PAYROLL_LEAVE_NAME"],
              });
              if (index + 1 == this.employee.length) {
                this.drawerData.DETAILS = [...[], ...this.drawerData.DETAILS];
                console.log(this.drawerData.DETAILS);
              }
            }
    
            // this.data.EMP_ID = data['data'][0]['ID'];
    
            this.Y_ID = data["data"][0]["YEAR_ID"];
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }

    this.drawerVisible = true;
  }



  
  empFilterStr = "";
  search(reset: boolean = false, exportInExcel: boolean = false) {
    this.filterQuery = "";

    if (reset) {
      this.pageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    if (this.searchText != "") {
      likeQuery = " AND (";
      this.columns.forEach((column) => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      this.filterQuery += likeQuery.substring(0, likeQuery.length - 3) + ")";
    }
    var dateFilter = "";
    if (this.date != undefined && this.date.length != 0) {
      // AND EMPLOYEE_ID='"+this.userId+"'
      dateFilter =
        "  AND (APPLIED_DATETIME BETWEEN '" +
        this.datePipe.transform(this.date[0], "yyyy-MM-dd 00:00:00") +
        "' AND '" +
        this.datePipe.transform(this.date[1], "yyyy-MM-dd 23:59:59") +
        "')";
    }
    
    if (this.employeeFilter != undefined && this.employeeFilter != null) {
      console.log("emp", this.employeeFilter);

      // empFilterStr = " AND EMPLOYEE_ID IN (" + this.employeeFilter + ")";
      this.empFilterStr = " AND EMP_ID='" + this.employeeFilter + "'";
    }

    var status = "";
    if (this.STATUS == "P") status = " and STATUS in ('P')";
    else if (this.STATUS == "A") status = " and STATUS in ('A')";
    else if (this.STATUS == "R") status = " and STATUS in ('R')";

    // + dateFilter
    // this.empId = "' AND EMPLOYEE_ID=' + this.data.ID";

    if(this.roleId == 3){
      // this.user= " AND EMP_ID='" + this.userId + "'";
      this.empFilterStr = " AND EMP_ID=" + this.userId + "";
    }

    this.loadingRecords = true;
    this.api
      .getLeaveEncashmentInfo(
        this.pageIndex,
        this.pageSize,
        this.sortKey,
        sort,
        this.filterQuery + this.empFilterStr + dateFilter + status
      )
      .subscribe(
        (data) => {
          if (data["code"] == 200) {
            this.loadingRecords = false;
            this.isFilterApplied = "primary";
            this.totalRecords = data["count"];
            this.dataList = data["data"];
            this.isSpinning = false;
            this.filterClass = "filter-invisible";
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }
}
