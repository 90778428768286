import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-bookdemoreport',
  templateUrl: './bookdemoreport.component.html',
  styleUrls: ['./bookdemoreport.component.css']
})
export class BookdemoreportComponent implements OnInit {
  formTitle = "Book Demo Report";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  fileName = 'BookDemoReport.xlsx';
  exportToExcelLoading = false
  index = 0;
  exportToExcelDataList: any = []
  loadingRecords = false;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  dateQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [["NAME", "Name"],
  ["EMAIL_ID", "Email Id"],
  ["MOBILE_NO", "Mobile No"],
  ["COMPANY_NAME", "Company Name"],
  ["DESCRIPTION", "Description"],
  ["PRODUCT_NAME","Product Name"],
  ["BOOKING_DATETIME","Booking DateTime"]]

  isSpinning = false
  filterClass: string = "filter-invisible";
  SALES_EXECUTIVE_ID: any = []
  Value1: any
  Value2: any
  selectedDate: Date[] = []
  dateFormat = 'dd/MM/yyyy';
  DATE :any=new Date();
  startValue: any = new Date();
  endValue: any = new Date();
  endOpen = false;
  startOpen = false;
  balance: any = []
  dataList:any=[]
  constructor(private api: ApiService, private datePipe: DatePipe, private _exportService: ExportService, private message: NzNotificationService) { }

  ngOnInit() {
    this.search(true);
  }

  onKeypressEvent(reset: any) {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    this.search(reset);
  }
 
 sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  exportexcel(): void {
    let element = document.getElementById('summer');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    // generate workbook and add the worksheet /
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // save to file /  
    XLSX.writeFile(wb, this.fileName);
  }

  search(reset: boolean = false, exportToExcel: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    console.log("search text : " + this.searchText);

    if (this.searchText != "") {
      likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")";
    }

    this.startValue =this.datePipe.transform(this.startValue,'yyyy-MM-dd');
    this.endValue=this.datePipe.transform(this.endValue,'yyyy-MM-dd');
    
    // if (this.startValue != null) {
    //   this.FROM_DATE = this.startValue
    // }
    // if (this.endValue != null) {
    //   this.TO_DATE = this.endValue
    // }
    if (this.startValue != null && this.endValue != null) {
      this.filterQuery = " AND DATE(CREATED_MODIFIED_DATE) BETWEEN '" + this.startValue + "' AND '" + this.endValue + "' "
    }
    
    if (exportToExcel == false) {
      this.loadingRecords = true

      this.api.bookdemoreport(this.pageIndex, this.pageSize, this.sortKey, sort, this.filterQuery + likeQuery ).subscribe(data => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.dataList = data['data'];
          this.filterClass = "filter-invisible";
        }
    }, err => {
        if (err['ok'] == false)
          this.message.error("Server Not Found", "");
      });
    } else {
      this.exportToExcelLoading = true;
      this.api.bookdemoreport(0, 0, this.sortKey, sort, this.filterQuery + likeQuery).subscribe(data => {
        if (data['code'] == 200) {
          this.exportToExcelLoading = false;
          this.exportToExcelDataList = data['data'];
          this.convertInExcel();
        }
      }, err => {
        if (err['ok'] == false)
          console.log("Server Not Found", "");
      });
    }
  }

  disabledStartDate = (startValue: Date): boolean => {
    if (!startValue || !this.endValue) {
      return false;
    }
    return startValue.getTime() > this.endValue;
  };
  current = new Date();

  disabledEndDate = (endValue: Date): boolean => {
    if (!endValue || !this.startValue) {
      return false;
    }
    return endValue.getTime() < this.startValue;
  };

  onStartChange(date: Date): void {
    this.startValue = date;
  }
  onEndChange(date: Date): void {
    this.endValue = date;
  }

  handleStartOpenChange(open: boolean): void {
    if (!open) {
      this.endOpen = true;
    }
  }

  handleEndOpenChange(open: boolean): void {
    this.endOpen = open;
  }

  clearFilter() {
    this.filterClass = "filter-invisible";
    this.isFilterApplied = "primary";
    this.startValue = new Date();
    this.endValue = new Date();
    this.search()
  }


  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";
  }

  Value: any
  FROM_DATE: any = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
  TO_DATE: any = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')
  applyFilter(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    this.isFilterApplied = 'primary'
    this.filterClass = "filter-invisible";
    this.loadingRecords = false;
    this.startValue = this.datePipe.transform(this.startValue, 'yyyy-MM-dd')
    this.endValue = this.datePipe.transform(this.endValue, 'yyyy-MM-dd')
 
  //   if (this.startValue != null) {
  //     this.FROM_DATE = this.startValue
  //   }

  
  //  if (this.endValue != null) {
  //     this.TO_DATE = this.endValue
  //   }
  if (this.startValue != null && this.endValue != null) {
    this.filterQuery = " AND DATE(CREATED_MODIFIED_DATE) BETWEEN '" + this.startValue + "' AND '" + this.endValue + "' "

    var filter = '';
    filter = this.filterQuery;
    var likeQuery = "";
  }
    this.search(true);
  }
  
  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();

    for (var i = 0; i < this.exportToExcelDataList.length; i++) {
      obj1['Name '] = this.exportToExcelDataList[i]['NAME'];
      obj1['Email Id'] = this.exportToExcelDataList[i]['EMAIL_ID'];
      obj1['Mobile No'] = this.exportToExcelDataList[i]['MOBILE_NO'];
      obj1['Company Name'] = this.exportToExcelDataList[i]['COMPANY_NAME'];
      obj1['Description'] = this.exportToExcelDataList[i]['DESCRIPTION'];
      obj1['Product Name']=this.exportToExcelDataList[i]['PRODUCT_NAME'];
      obj1['Booking DateTime']=this.exportToExcelDataList[i]['BOOKING_DATETIME'];

      arry1.push(Object.assign({}, obj1));
      if (i == this.exportToExcelDataList.length - 1) {
        this._exportService.exportExcel(arry1, 'Book Demo Report ' + this.datePipe.transform(new Date(), 'dd-MMM-yy, hh mm ss a'));
      }
    }
  }
}
