import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { differenceInCalendarDays, setHours } from 'date-fns';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';
// import { contactInfo } from 'src/app/Models/contactInfo';
import * as xlsx from 'xlsx';

@Component({
  selector: 'app-contact-info-report',
  templateUrl: './contact-info-report.component.html',
  styleUrls: ['./contact-info-report.component.css']
})
export class ContactInfoReportComponent implements OnInit {

  // @Input() data: contactInfo = new contactInfo();
  dateFormat = "dd/MMM/yyyy";
  formTitle = "Contact Info Report";
  fileName = "ContactInfoReport.xlsx";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  exportToExcelDataList = [];
  loadingRecords = true;
  exportToExcelLoading = false;
  sortKey: string = "ID";
  sortValue: string = "desc";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [["NAME", "Name"], ["EMAIL_ID", "Email Id"], ["MOBILE_NO", "Mobile No."], ["SUBJECT", "Subject"], ["MESSAGE", "Message"]];
  isSpinning = false;
  loadingFilterEmployees = true;
  filterClass: string = "filter-visible";
  date: Date[] = [];
  startValue: any = new Date();
  endValue: any = new Date();
  endOpen = false;
  // CREATED_MODIFIED_DATE:Date[]=[];


  constructor(private api: ApiService, private datePipe: DatePipe, private message: NzNotificationService, private _exportService: ExportService, private cookie: CookieService) { }

  ngOnInit() {
    this.date[0] = new Date();
    this.date[1] = new Date();
    this.search();
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
  }

  sort(sort: any): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    if (this.sortValue == "descend") {
      this.sortValue = 'desc';
    } else {
      this.sortValue = 'asc'
    }
    console.log(this.sortValue + "kk");
    this.search(true);
  }


  orgId = this.cookie.get('orgId');

  exportLoading: boolean = false;

  importInExcel() {
    this.search(true, true);
  }

  search(reset: boolean = false, exportInExcel: boolean = false) {
    this.filterQuery = "";

    if (reset) {
      this.pageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    if (this.searchText != "") {
      likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      this.filterQuery += likeQuery.substring(0, likeQuery.length - 3) + ")";
    }

    var dateFilter = "";
    if ((this.date != undefined) && (this.date.length != 0)) {
      dateFilter = " AND (CREATED_MODIFIED_DATE BETWEEN '" + this.datePipe.transform(this.date[0], 'yyyy-MM-dd 00:00:00') + "' AND '" + this.datePipe.transform(this.date[1], 'yyyy-MM-dd 23:59:59') + "')";
    }


    if (exportInExcel == false) {
      this.loadingRecords = true;
      this.api.getContactInfo(this.pageIndex, this.pageSize, this.sortKey, sort, this.filterQuery + dateFilter).subscribe(data => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.isFilterApplied = 'primary';
          this.totalRecords = data['count'];
          this.dataList = data['data'];
          this.isSpinning = false;
          this.filterClass = 'filter-invisible';

        }

      }, err => {
        console.log(err);
      });

    }
    else {
      this.exportLoading = true;
      this.api.getContactInfo(0, 0, this.sortKey, sort, this.filterQuery + dateFilter).subscribe
        (data => {
          if (data['code'] == 200) {
            this.exportLoading = false;
            this.dataList = data['data'];
            this.convertInExcel();
          }
        },
          err => {
            if (err['ok'] == false)
              this.message.error("Server Not Found", "");
          });
    }
  }
  onKeypressEvent() {
    document.getElementById("searchBtn").focus();
  }

  keyup(event: any) {
    this.search();
  }

  getDaysArray(start: any, end: any) {
    for (
      var arr = [], dt = new Date(start);
      dt <= new Date(end);
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(this.datePipe.transform(dt, 'yyyy-MM-dd'));
      this.dates.push(this.datePipe.transform(dt, 'yyyy-MM-dd'));
    }
    return arr;
  }

  timeDefaultValue = setHours(new Date(), 0);

  dates: any = [];
  today2 = new Date();


  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  drawerClose(): void {
    this.search();
  }

  clearFilter() {

    this.date = [];
    this.date[0] = new Date();
    this.date[1] = new Date();
    this.filterClass = 'filter-invisible';
    this.isFilterApplied = 'default';
    this.filterQuery = "";
    this.search(true);
  }

  applyFilter() {

    if (this.date.length > 0) {
      this.isFilterApplied = "primary";
    }
    else {
      this.isFilterApplied = "default";
    }
    this.search(true);
    this.filterClass = "filter-invisible";


  }

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";

    else
      this.filterClass = "filter-visible";
  }

  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.dataList.length; i++) {
      obj1['Name'] = this.dataList[i]['NAME'];
      obj1['Email Id'] = this.dataList[i]['EMAIL_ID'];
      obj1['Mobile No.'] = this.dataList[i]['MOBILE_NO'];
      obj1['Subject'] = this.dataList[i]['SUBJECT'];
      obj1['Message'] = this.dataList[i]['MESSAGE'];

      arry1.push(Object.assign({}, obj1));
      if (i == this.dataList.length - 1) {
        this._exportService.exportExcel(arry1, 'Contact Info ' + this.datePipe.transform(new Date(), 'dd-MMM-yy'));
      }
    }
  }


}
