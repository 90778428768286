import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent implements OnInit {
  userName = this.cookie.get('userName');
  emailId = this.cookie.get('emailId');
  currentMonth: string = "";
  currentYear: string = "";
  EOM_Name: string = "";
  EOM_Dept: string = "";
  EOM_Designation: string = "";
  EOM_Branch: string = "";
  EOM_MONTH: string = "";
  EOM_YEAR: string = "";

  EOM_Image: string = undefined;
  isEOMLoading: boolean = false;

  constructor(private cookie: CookieService, private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.getEOM();
    this.currentMonth = this.getMonth();
   
  }

  getMonth() {
    var d = new Date()
   
    let currentMonth = d.getMonth()-1;
    console.log(currentMonth);
    
    if (currentMonth == 0){
      this.currentYear = String(new Date().getFullYear());
      return "January";
    }
     else if (currentMonth == 1){
      this.currentYear = String(new Date().getFullYear());
      return "February";
     }
      

    else if (currentMonth == 2){
      this.currentYear = String(new Date().getFullYear());
      return "March";
    }
      

    else if (currentMonth == 3){
      this.currentYear = String(new Date().getFullYear());
      return "April";
    }
      

    else if (currentMonth == 4){
      this.currentYear = String(new Date().getFullYear());
      return "May";
    }
      

    else if (currentMonth == 5){
      this.currentYear = String(new Date().getFullYear());
      return "June";
    }
      

    else if (currentMonth == 6){
      this.currentYear = String(new Date().getFullYear());
      return "July";
    }
     

    else if (currentMonth == 7){
      this.currentYear = String(new Date().getFullYear());
      return "August";
    }
      

    else if (currentMonth == 8){
      this.currentYear = String(new Date().getFullYear());
      return "September";
    }
      

    else if (currentMonth == 9){
      this.currentYear = String(new Date().getFullYear());
      return "October";
    }
      

    else if (currentMonth == 10){
      this.currentYear = String(new Date().getFullYear());
      return "November";
    }
      

    else if (currentMonth == -1){
      this.currentYear = String(new Date().getFullYear()-1);
      return "December";
    }
     
  }

  getEOM() {
    this.isEOMLoading = true;

    this.api.getEmployeeOfTheMonth(0, 0, "", "", "").subscribe(data => {
      if (data["code"] == 200) {
        this.isEOMLoading = false;
        this.EOM_Name = data["EMPLOYEE_OF_THE_MONTH"][0]["NAME"];
        this.EOM_Dept = data["EMPLOYEE_OF_THE_MONTH"][0]["DEPARTMENT_NAME"];
        this.EOM_Designation = data["EMPLOYEE_OF_THE_MONTH"][0]["DESIGNATION_NAME"];
        this.EOM_Branch = data["EMPLOYEE_OF_THE_MONTH"][0]["BRANCH_NAME"];
        this.EOM_YEAR = data["EMPLOYEE_OF_THE_MONTH"][0]["YEAR_OF_EOM"];
        this.EOM_MONTH = data["EMPLOYEE_OF_THE_MONTH"][0]["MONTH_OF_EOM"];
        this.EOM_Image = data["EMPLOYEE_OF_THE_MONTH"][0]["PROFILE_PHOTO"] ? this.api.retriveimgUrl + "profilePhoto/" + data["EMPLOYEE_OF_THE_MONTH"][0]["PROFILE_PHOTO"] : "assets/profile1.png";
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }
}
