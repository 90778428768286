import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/Service/api.service';
import { DatePipe } from '@angular/common';
import { NzNotificationService } from 'ng-zorro-antd';
import { ExportService } from 'src/app/Service/export.service';

@Component({
  selector: 'app-job-application-report',
  templateUrl: './job-application-report.component.html',
  styleUrls: ['./job-application-report.component.css']
})
export class JobApplicationReportComponent implements OnInit {
  formTitle = "Job Application Report";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  // userId = this.cookie.get('userId')
  // roleId = this.cookie.get('roleId')
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  // fileName= 'UserWise.xlsx';
  isFilterApplied: string = "default";
  columns: string[][] = [["EMAIL_ID", "E-mail ID"], ["MOBILE_NO", "Mobile No"],
  ["STATE_NAME", "State"], ["COLLEGE_NAME", "College"], ["BRANCH_NAME", "Branch"], ["YEAR_NAME", "Year"], ["NAME", "Name"]]
  isSpinning = false
  filterClass: string = "filter-invisible";
  selectedDate: Date[] = []
  dateFormat = 'dd/MM/yyyy';
  ticketQuestion = {};
  date: Date[] = [];

  startValue: any;
  endValue: any;
  constructor(private api: ApiService, private datePipe: DatePipe, private message: NzNotificationService, private _exportService: ExportService) { }

  ngOnInit() {
    this.date[0] = new Date();
    this.date[1] = new Date();
    this.search();
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
  }
  exportLoading: boolean = false;

  importInExcel() {
    this.search(true, true);
  }

  search(reset: boolean = false, exportInExcel: boolean = false) {
    this.filterQuery = "";

    if (reset) {
      this.pageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    if (this.searchText != "") {
      likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      this.filterQuery += likeQuery.substring(0, likeQuery.length - 3) + ")";
    }

    var dateFilter = "";
    if ((this.date != undefined) && (this.date.length != 0)) {
      dateFilter = " AND (CREATED_MODIFIED_DATE BETWEEN '" + this.datePipe.transform(this.date[0], 'yyyy-MM-dd 00:00:00') + "' AND '" + this.datePipe.transform(this.date[1], 'yyyy-MM-dd 23:59:59') + "')";
    }

    if (exportInExcel == false) {
      this.loadingRecords = true
      this.api.getApplicationReport(this.pageIndex, this.pageSize, this.sortKey, sort, this.filterQuery + dateFilter).subscribe(data => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.dataList = data['data'];
          this.isSpinning = false
          this.isFilterApplied = "primary";
          this.filterClass = "filter-invisible";
        }

      }, err => {
        if (err['ok'] == false)
          console.log("Server Not Found", "");
      });
    }

    else {
      this.exportLoading = true;
      this.api.getApplicationReport(0, 0, this.sortKey, sort, this.filterQuery + dateFilter).subscribe
        (data => {
          if (data['code'] == 200) {
            this.exportLoading = false;
            this.dataList = data['data'];
            this.convertInExcel();
          }
        },
          err => {
            if (err['ok'] == false)
              this.message.error("Server Not Found", "");
          });
    }
  }


  getAttachment(url: string) {
    window.open(this.api.retriveimgUrl + "Resumes/" + url);
  }


  sort(sort: any): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    if (this.sortValue == "descend") {
      this.sortValue = 'desc';
    } else {
      this.sortValue = 'asc'
    }
    console.log(this.sortValue + "kk");
    this.search(true);
  }

  onKeypressEvent() {
    const element = window.document.getElementById('button');
    if (element != null) element.focus();
    this.search();
  }
  keyup(event: any) {
    this.search();
  }
  
  dates: any = [];
  today2 = new Date();


  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";

    else
      this.filterClass = "filter-visible";

  }

  applyFilter() {
    if (this.date.length > 0) {
      this.isFilterApplied = "primary";
    }
    else {
      this.isFilterApplied = "default";
    }
    this.search(true);
    this.filterClass = "filter-invisible";

    // // this.isSpinning = true
    // this.loadingRecords = true;

    // this.startValue = this.datePipe.transform(this.startValue, 'yyyy-MM-dd');
    // this.endValue = this.datePipe.transform(this.endValue, 'yyyy-MM-dd');


    // var sort: string;
    // try {
    //   sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    // } catch (error) {
    //   sort = '';
    // }

    // this.filterQuery = '';
    // this.startValue = this.datePipe.transform(this.startValue, 'yyyy-MM-dd');
    // this.endValue = this.datePipe.transform(this.endValue, 'yyyy-MM-dd');

    // if (this.startValue != null && this.endValue != null) {
    //   this.filterQuery = " AND CREATED_MODIFIED_DATE BETWEEN '" + this.startValue + "' AND '" + this.endValue + "' "

    //   var filter = '';
    //   filter = this.filterQuery;
    //   var likeQuery = "";
    // }

    // this.api.getApplicationReport(0, 0, this.sortKey, sort, this.filterQuery).subscribe(data => {
    //   console.log(data)
    //   this.loadingRecords = false;
    //   this.totalRecords = data['count'];
    //   this.dataList = data['data'];
    //   this.isSpinning = false
    //   this.isFilterApplied = "primary";
    //   this.filterClass = "filter-invisible";
    // }, err => {
    //   console.log(err);
    // });
  }
  clearFilter() {

  this.date = [];
    this.date[0] = new Date();
    this.date[1] = new Date();
    this.filterClass = 'filter-invisible';
    this.isFilterApplied = 'default';
    this.filterQuery = "";
    this.search(true);
  }


  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();
    for (var i = 0; i < this.dataList.length; i++) {
      obj1['E-mail ID'] = this.dataList[i]['EMAIL_ID'];
      obj1['Mobile No'] = this.dataList[i]['MOBILE_NO'];
      obj1['State'] = this.dataList[i]['STATE_NAME'];
      obj1['College'] = this.dataList[i]['COLLEGE_NAME'];
      obj1['Branch'] = this.dataList[i]['BRANCH_NAME'];
      obj1['Year'] = this.dataList[i]['YEAR_NAME'];
      obj1['Name'] = this.dataList[i]['NAME'];

      arry1.push(Object.assign({}, obj1));
      if (i == this.dataList.length - 1) {
        this._exportService.exportExcel(arry1, 'Job Application' + this.datePipe.transform(new Date(), 'yyyy-mm'));
      }
    }
  }
}
