import { DatePipe } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { NzNotificationService } from "ng-zorro-antd";
import { CookieService } from "ngx-cookie-service";
// import { LeaveEncashment } from "src/app/Models/LeaveEncashment";
import { EmployeeMaster } from "src/app/Models/employeemaster";
import { LeaveEncashment } from "src/app/Models/LeaveEncashment (1)";
import { leaveEncashRequestCheck } from "src/app/Models/leaveEncashRequestCheck";
import { ApiService } from "src/app/Service/api.service";
import { ExportService } from "src/app/Service/export.service";

@Component({
  selector: "app-add-leave-encashment",
  templateUrl: "./add-leave-encashment.component.html",
  styleUrls: ["./add-leave-encashment.component.css"],
})
export class AddLeaveEncashmentComponent implements OnInit {
  loadingRecords: boolean = false
  @Input() drawerClose: Function;
  @Input() data: LeaveEncashment;
  @Input() drawerVisible: boolean;
  @Input() showRadio: boolean;
  @Input() viewDetails: boolean;
  @Input() date;
  @Input() YEAR_ID;

  formTitle = "New Leave Encashment Applications";
  // date: any;
  empLoader: boolean = false;
  EMP_ID: any;
  ID: any;
  roleId: any = Number(this.cookie.get("roleId"));
  userId = Number(this.cookie.get("userId"));
  filterEmployees: EmployeeMaster[];
  CheckData: leaveEncashRequestCheck = new leaveEncashRequestCheck();

  totalRecords = 1;
  sortValue: string = "desc";
  sortKey: string = "ID";

  loadingFilterEmployees = true;
  EncashLeave = 0;
  STATUS = 'P';
  DisableRemark = false;

  i: any
  // CHECKED_REMARK: any;

  constructor(
    private api: ApiService,
    private datePipe: DatePipe,
    private message: NzNotificationService,
    private _exportService: ExportService,
    private cookie: CookieService
  ) { }

  ngOnInit() {
    this.date = new Date();
    console.log("date", this.date);
    this.loadDataFilter();
    this.checkEmpRemark();

    // this.date[1] = new Date();
  }

  changeRadioButton(Status) {
    this.STATUS = Status;
  }

  checkEmpRemark() {
    if ((this.roleId == 14 || this.roleId == 2) && (this.viewDetails || this.showRadio)) {
      this.DisableRemark = true;
    }
  }

  loadDataFilter() {
    this.loadingFilterEmployees = true;

    this.api.getAllEmployees(0, 0, "NAME", "asc", "").subscribe(
      (employees) => {
        if (employees["code"] == 200) {
          this.filterEmployees = employees["data"];
          this.loadingFilterEmployees = false;
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  close() {
    this.drawerClose();
  }

  sum: number = 0;
  updateSum(event: any): void {
    this.sum = this.data.DETAILS.reduce((total, item) => total + item.APPLIED_BALANCE, 0);
  }

  allemps = []
  employee = [];
  columns4 = []
  dataList: any = []
  // YEAR_ID;
  filterEmpData(event: any) {
    console.log("eventevent", event);

    this.empLoader = true;
    if (event != null) {
      this.api
        .getLeaveEncashment(event, 0, 0, "", "", " AND ID =" + event)
        .subscribe(
          (data) => {
            if (data["code"] == 200) {
              this.emp_id = event;
              this.empLoader = false;
              this.employee = data["data"];

              this.data.DETAILS = [];
              for (let index = 0; index < this.employee.length; index++) {
                this.data.DETAILS.push({
                  ID: this.employee[index]["ID"],
                  LEAVE_TYPE_ID: this.employee[index]["PAYROLL_LEAVE_ID"],
                  APPLIED_BALANCE: 0,
                  AVAILABLE_BALANCE: this.employee[index]["BALANCE"],
                  BALANCE: this.employee[index]["BALANCE"],
                  LEAVE_TYPE_NAME:
                    this.employee[index]["PAYROLL_LEAVE_NAME"],
                });
                if (index + 1 == this.employee.length) {
                  this.data.DETAILS = [...[], ...this.data.DETAILS];
                  console.log(this.data.DETAILS);
                }
              }

              // this.data.EMP_ID = data['data'][0]['ID'];

              this.YEAR_ID = data["data"][0]["YEAR_ID"];
            }
          },
          (err) => {
            console.log(err);
          }
        );

      this.api.getAllEmployeeLeave123(0, 0, this.sortKey, this.sortValue, 'AND EMPLOYEE_ID = ' + event + ' AND LEAVE_POLICY_STATUS = 1', '').subscribe(data => {
        console.log(data['data']);
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];

      }, err => {
        console.log(err);
      });
    }

    else {
      this.empLoader = false;
      this.data.ID = null;
      // this.data.OFFICE_NAME = '';
    }
  }
  emp_id: any;
  // CHECKED_REMARK: any;

  checkedDate: any = new Date();
  appliedBalance;

  save() {

    if (!this.showRadio) {
      var isOk = true;
      console.log("calling save function .. ");

      this.checkedDate = this.datePipe.transform(
        this.checkedDate,
        "yyyy-MM-dd HH:mm:ss"
      );

      if (
        this.data.ID == undefined ||
        this.data.ID == 0 ||
        this.data.ID == null
      ) {
        if (this.roleId == 14 || this.roleId == 2) {
          this.data.STATUS = "A";
          this.data.IS_FROM_ADMIN = 1;
          this.data.CHECKED_BY_ID = this.userId;
          this.data.CHECKED_REMARK = this.data.CHECKED_REMARK;
          this.data.CHECKED_DATETIME = this.checkedDate;
          this.data.EMP_REMARK = "";
          this.data.EMP_ID = this.emp_id;



          if (this.data.CHECKED_REMARK == undefined || this.data.CHECKED_REMARK <= 0) {
            isOk = false;
            this.message.error('Please Enter Remark', '');
          }

        }
        if (this.roleId == 3) {
          this.data.STATUS = "P";
          this.data.EMP_ID = this.userId
          this.data.EMP_REMARK = this.data.EMP_REMARK;

          if (this.data.EMP_REMARK == undefined || this.data.EMP_REMARK <= 0) {
            isOk = false;
            this.message.error('Please Enter Employee Remark', '');
          }

        }


        this.data.APPLIED_DATETIME = this.datePipe.transform(
          this.date,
          "yyyy-MM-dd HH:mm:ss")



      } else {
        this.data.CHECKED_BY_ID = this.userId;
        this.data.CHECKED_REMARK = this.data.CHECKED_REMARK;
        this.data.CHECKED_DATETIME = this.checkedDate;
      }

      this.data.YEAR_ID = this.YEAR_ID;

      // this.data.YEAR_ID=

      var isAboveZero = false;

      for (let i = 0; i < this.data.DETAILS.length; i++) {
        if (this.data.DETAILS[i]["APPLIED_BALANCE"] > 0) {
          isAboveZero = true;
        }
        if (i + 1 == this.data.DETAILS.length) {
          if (isAboveZero == false) {
            isOk = false;
            this.message.error("Please Enter Applied Balance", "");
          }
        }
      }

      console.log(this.data);

      // this.data.DETAILS = this.employee;
      if (isOk) {
        this.api
          .createLeaveEncashRequest(this.data, this.YEAR_ID)
          .subscribe((successCode) => {
            if (successCode["code"] == "200") {
              console.log("successcode: " + JSON.stringify(successCode));
              this.message.success(" Information Created Successfully...", "");
              this.EMP_ID = "";
              this.drawerClose();
            } else {
              this.message.error(" Information Creation Failed...", "");
            }
          });
      }
    }

    if (this.showRadio) {
      let isOk = true;



      this.CheckData.CHECKED_BY_ID = this.userId;
      this.CheckData.CHECKED_REMARK = this.data.CHECKED_REMARK;
      this.CheckData.CHECKED_DATETIME = this.date;
      this.CheckData.STATUS = this.STATUS;
      this.CheckData.ID = this.data.ID;

      this.CheckData.CHECKED_DATETIME = this.datePipe.transform(
        this.checkedDate,
        "yyyy-MM-dd HH:mm:ss"
      );

      if (this.data.CHECKED_REMARK == undefined || this.data.CHECKED_REMARK == null) {
        isOk = false;
        this.message.error('Please Enter Remark', '');
      }

      if (isOk) {

        this.api
          .LeaveEncashRequestCheck(this.CheckData)
          .subscribe((successCode) => {
            if (successCode["code"] == "200") {
              console.log("successcode: " + JSON.stringify(successCode));
              this.message.success(" Information Created Successfully...", "");
              this.drawerClose();
            } else {
              this.message.error(" Information Creation Failed...", "");
            }
          });
      }
    }

  }
}
//status
//apply button
//date
// disableremark
