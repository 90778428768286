import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NzDatePickerComponent, NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
// import { ApiService } from '../Service/api.service';
import { differenceInCalendarDays } from 'date-fns';
import { NgForm } from '@angular/forms';
import { Leave } from 'src/app/Models/Leave';
import { ApiService } from 'src/app/Service/api.service';
// import { rateMaster } from 'src/app/Models/IncentiveRateMaster';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
// import { leaveMaster } from '../LeaveMaster';
import { leaveadjustment } from 'src/app/Models/leaveadjustment';
import { leaveMaster } from '../../Leave Master/LeaveMaster';
// import { leaveadjustment } from 'src/app/Models/leaveadjustment';
// import {leaveadjustment} from 'src/app/Models/l'
// import { leaveMaster } from 'src/app/Models/LeaveMaster';
// import { Leave } from '../Models/Leave';
@Component({
  selector: 'app-new-leave-adjustment',
  templateUrl: './new-leave-adjustment.component.html',
  styleUrls: ['./new-leave-adjustment.component.css']
})
export class NewLeaveAdjustmentComponent implements OnInit {

  @Input() drawerClose: Function
  @Input() dataList111 = []
  // @Input() data: leaveadjustment;
  @Input() dataListtt = []

  @Input() drawerVisible: boolean;
  isSpinning = false;
  isSpinning11 = false;
  roles = [];
  model = [];
  leaveTypes = [];
  formTitle = "Leave Balance Adjustment Form"
  // dataList = [];
  @Input() MODELS = [];
  @Input() ROLES = [];
  @Input() SendData = [];

  @Input() ADJUSTMENT:any
  @Input() CLOSING:any
  @Input() OPENING:any



  Array1 = [];
  dataList1 = []
  disabled1 = true
  disabled2 = true
  leavedata = new leaveMaster();

  MODEL1: any
  FULES: any
  VARIENT: any
  TRANSMISSION: any

  @Input() selectedIndex

  pageIndex = 1;
  dataaa: number
  pageSize = 10;
  totalRecords = 1;
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  // @Input() columns = []
  columns2: string[][] = [["EMPLOYEE_NAME", "Employees"]]

  columns: string[][] = [
  ["EL_ADJ", "Opening"],
  ["EL_CL", "CL"],
  ["EL_OP", "Medical	Total"],
  
  ["CL_ADJ", "Casual	Total"],
  ["CL_CL", "Casual	Total"],
  ["CL_OP", "Casual	Total"],

  ["ML_ADJ", "LWP	Total"],
  ["ML_CL", "LWP	Total"],
  ["ML_OP", "LWP	Total"],

  ["CO_ADJ", "CompOff	Total"],
  ["CO_CL", "CompOff	Total"],
  ["CO_OP", "CompOff	Total"],

  ["LWP_ADJ", "LWP	Total"],
  ["LWP_CL", "LWP	Total"],
  ["LWP_OP", "LWP	Total"],

  ["MGL_ADJ", "marriage	Total"],
  ["MGL_CL", "marriage	Total"],
  ["MGL_OP", "marriage	Total"],


  ]

//   new(){
//   this.api.getLeaveData(0, 0, '', '', 'AND STATUS=1').subscribe(data2 => {
//     if (data2['code'] == 200) {
//       this.allemps = data2['data']
//       this.columns = data2['data']


//       // for (let h = 0; h < this.dataList.length; h++) {
//       // this.dataList[0].split(",")
//       var result: any = Object.keys(this.dataList111[0]).map((key) => [(key), this.dataList111[0][key]]);

//       for (let j = 0; j < result.length; j++) {
//         //  if( this.dataList[i].EMPLOYEE_ID == this.allemps[j]['ID'])
//         //  this.dataList['NAME'] =(this.allemps[j]['NAME'])
//         var s = result[j][0].split('_')

//         for (let i = 0; i < this.allemps.length; i++) {
//           console.log(s[0]);
       
//             var a = result[j][0].split('_')
//             if ('ADJ' == a[1])
//               this.columns[i]['ADJUSTMENT'] = result[j][0];
//             if ('CL' == a[1])
//               this.columns[i]['CLOSING'] = result[j][0];
//             if ('OP' == a[1])
//               this.columns[i]['OPENING'] = result[j][0];
//             console.log(this.columns[i])
          

//         }
//         if (j + 1 == result.length) {

//         }
//       }
//     }

//   }, err => {
//     if (err['ok'] == false)
//       this.message.error("Server Not Found", "");
//   });
// }

  orgId = this.cookie.get('orgId');
  finalData = []
  ROLES2 = []
  MODELS2 = []
  modeltype = []
  fueltype = []
  varienttype = []
  tranmission = []


  DESIGNATION_ID: any
  DEPARTMENT_ID: any
  BRANCH_ID: any
  // @Input() filterbutton: boolean = true;
  // @Input() tablehide: boolean;
  // @Input() startbutton: boolean;

  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe, private cookie: CookieService) { }

  ngOnInit() {
    this.getModels();
  }

  filterClass: string = 'filter-invisible';

  showFilter() {
    if (this.filterClass === 'filter-visible')
      this.filterClass = 'filter-invisible';
    else this.filterClass = 'filter-visible';

  }

  cancel() {
    this.drawerClose()
    this.dataList111 = []
    this.isSpinning = false;
  }
 
  
   updateClosingEarnedLeave(data: any,column,index) {
  //  console.log(this.dataList111[index]);
   

   
    if(column == 'EL_ADJ'){
      this.dataList111[index].EL_ADJ = data
      this.dataList111[index].EL_CL =  this.dataList111[index].EL_OP +  this.dataList111[index].EL_ADJ;
      console.log(column);
    }else{
      this.dataList111[index].EL_CL = data
      this.dataList111[index].EL_ADJ = this.dataList111[index].EL_CL - this.dataList111[index].EL_OP;
      console.log(column);

    }

  }

  
  updateClosingCasualLeave(data: any,column,index) {
    if(column == 'CL_ADJ'){
      this.dataList111[index].CL_ADJ = data
      this.dataList111[index].CL_CL =  this.dataList111[index].CL_OP +  this.dataList111[index].CL_ADJ;
      console.log(column);
    }else{
      this.dataList111[index].CL_CL = data
      this.dataList111[index].CL_ADJ = this.dataList111[index].CL_CL - this.dataList111[index].CL_OP;
      console.log(column);

    }
  }

  updateClosingmedicalLeave(data: any,column,index) { 
    if(column == 'ML_ADJ'){
      this.dataList111[index].ML_ADJ = data
      this.dataList111[index].ML_CL =  this.dataList111[index].ML_OP +  this.dataList111[index].ML_ADJ;
      console.log(column);
    }else{
      this.dataList111[index].ML_CL = data
      this.dataList111[index].ML_ADJ = this.dataList111[index].ML_CL - this.dataList111[index].ML_OP;
      console.log(column);

    }
  }

  updateClosingLWPLeave(data: any,column,index) {
    if(column == 'LWP_ADJ'){
      this.dataList111[index].LWP_ADJ = data
      this.dataList111[index].LWP_CL =  this.dataList111[index].LWP_OP +  this.dataList111[index].LWP_ADJ;
      console.log(column);
    }else{
      this.dataList111[index].LWP_CL = data
      this.dataList111[index].LWP_ADJ = this.dataList111[index].LWP_CL - this.dataList111[index].LWP_OP;
      console.log(column);

    }
  }

  updateClosingCompLeave(data: any,column,index) {
    if(column == 'CO_ADJ'){
      this.dataList111[index].CO_ADJ = data
      this.dataList111[index].CO_CL =  this.dataList111[index].CO_OP +  this.dataList111[index].CO_ADJ;
      console.log(column);
    }else{
      this.dataList111[index].CO_CL = data
      this.dataList111[index].CO_ADJ = this.dataList111[index].CO_CL - this.dataList111[index].CO_OP;
      console.log(column);

    }
  }

  updateClosingMGLLeave(data: any,column,index) {
    data.MGL_CL = data.MGL_OP + data.MGL_ADJ;
    if(column == 'MGL_ADJ'){
      this.dataList111[index].MGL_ADJ = data
      this.dataList111[index].MGL_CL =  this.dataList111[index].MGL_OP +  this.dataList111[index].MGL_ADJ;
      console.log(column);
    }else{
      this.dataList111[index].MGL_CL = data
      this.dataList111[index].MGL_ADJ = this.dataList111[index].MGL_CL - this.dataList111[index].MGL_OP;
      console.log(column);

    }
  }
  

  clearFilter() {
    this.filterClass = 'filter-invisible';

    this.isFilterApplied = 'default';
    this.filterQuery = '';
    this.BRANCH_ID = []
    this.DEPARTMENT_ID = []
    this.DESIGNATION_ID = []
    this.dataList111 = [];
    this.search();
    // this.data.ALLOTMENT_TYPE_ID = null;

  }
  // isSpinning = false


  sort(sort: any): void {

    this.sortKey = sort.key;
    this.sortValue = sort.value;


    if (this.sortValue == "descend") {
      this.sortValue = 'desc';
    } else {
      this.sortValue = 'asc'
    }
    console.log(this.sortValue + "kk");
    this.search(true);
  }



  applyFilter() {


    if (

      this.BRANCH_ID != null ||
      this.DEPARTMENT_ID != null ||
      this.DESIGNATION_ID != null
    ) {
      this.isFilterApplied = "primary";
    }
    else this.isFilterApplied = "default";

    this.filterClass = "filter-invisible";

    this.search(true);
  }

  lastid: number

  
  EMPID = []
  allemps = []
  allempids = []
 @Input() employess=[]

  search(reset: boolean = false) {
    var filter = ""
    this.filterQuery = ''
    if (reset) {
      this.pageIndex = 1;
    }

    // this.loadingRecords = true;
    var sort: string;

    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    console.log("search text : " + this.searchText);
    // console.log(sort);

    var likeQuery = "";

    if (this.searchText != "") {
      likeQuery = " AND ";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
    }

    
    var BranchFilter: any = "";
    if (this.BRANCH_ID != undefined && this.BRANCH_ID.length != 0) {
      BranchFilter = this.BRANCH_ID;
      this.filterQuery += " AND BRANCH_ID = " + this.BRANCH_ID;
    }

    var DepartmentFilter: any = "";
    if (this.DEPARTMENT_ID != undefined && this.DEPARTMENT_ID.length != 0) {
      DepartmentFilter = this.DEPARTMENT_ID;
      this.filterQuery += " AND DEPARTMENT_ID = " + this.DEPARTMENT_ID;
    }

    var DesignationFilter: any = "";
    if (this.DESIGNATION_ID != undefined && this.DESIGNATION_ID.length != 0) {
      DesignationFilter = this.DESIGNATION_ID;
      this.filterQuery += " AND DESIGNATION_ID = " + this.DESIGNATION_ID;
    }


        var adjustFilter: any = "";

        adjustFilter = this.lastid;
        // this.filterQuery += " AND LEAVE_BALANCE_ADJUSTMENT_MASTER_ID = " + adjustFilter;

        this.api.getAllleaveadjustment(0, 0, this.sortKey, this.sortValue,this.filterQuery).subscribe(data => {
          console.log(data['data']);
          this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.dataList111 = data['data'];
        

        }, err => {
          console.log(err);
        });


  


  }


  loadRoles() {
    this.api.getAllRoles(0, 0, '', '', ' AND STATUS=1 ').subscribe(roles => {
      this.roles = roles['data'];
    }, err => {
      console.log(err);
    });
  }

  getModels() {
    this.model = [];

    this.api.getAllDesignation(0, 0, '', 'asc', 'AND STATUS=1').subscribe(data => {
      if (data['code'] == 200) {
        this.modeltype = data['data'];
      }
    }, err => {
      console.log(err);
    });

    this.api.getAllDepartments(0, 0, '', 'asc', 'AND STATUS=1').subscribe(data => {
      if (data['code'] == 200) {
        this.varienttype = data['data'];
      }
    }, err => {
      console.log(err);
    });

    this.api.getAllAttendanceBranch(0, 0, '', 'asc', 'AND STATUS=1').subscribe(data => {
      if (data['code'] == 200) {
        this.fueltype = data['data'];
      }
    }, err => {
      console.log(err);
    });


  }


  close(): void {
    this.drawerClose();

    this.isSpinning = false;
  }


  MappingData
  MODELeee = []

  Modeldata = []
  // SendData = []
  SendData1 = []



  finalDataa = []
  // dataListtt = []
  ROLES222 = []
  ROLESSS = []
  MODELSSSS = []

  MODELIDS = []
  Final = []
  va = []
  ids = []
  maindata = []
  sendthejson = []


  isVisible11 = false;


  save(dataListtt: any) {
    this.isSpinning = true;
    console.log(dataListtt);
    

    this.api.newleaveAdjustmentaddbulk(dataListtt).subscribe(successCode => {
      if (successCode['code'] == "200") {
        this.message.success("Leave Adjustment Updated Successfully", "");
 

        this.drawerClose()
      } else {
        this.message.error("Leave Adjustment Updation Failed", "");
        this.isSpinning = false;
      }
    });

  }

  todaydate: any
  data24



  lastid1: any
  date111: any
  dataaaa: any
  ide: any
  isOk = false;
  IS_COMPLETED: any



}
