export class leaveEncashRequestCheck {
    ID: number;

    CHECKED_DATETIME: any;
    STATUS:any;
    CHECKED_REMARK:any;
    CHECKED_BY_ID:any
    
   

    
}