import { Component, OnInit, ViewChild } from "@angular/core";
import { DatePipe } from "@angular/common";
import { NzDatePickerComponent, NzNotificationService } from "ng-zorro-antd";
import { UpdateAttendanceRegister } from "src/app/Models/UpdateAttendanceRegister";
import { ApiService } from "src/app/Service/api.service";
import { CookieService } from "ngx-cookie-service";
import { ExportService } from "src/app/Service/export.service";
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-leave-register',
  templateUrl: './leave-register.component.html',
  styleUrls: ['./leave-register.component.css']
})
export class LeaveRegisterComponent implements OnInit {
  monthFormat = "yyyy-MMM-dd";
  formTitle = "Leave Register";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  startValue: Date | null = null;
  startValue1 = undefined;
  endValue: Date | null = null;
  endOpen = false;
  startOpen = false;
  isFilterApplied: string = "default";
  columns1: string[][] = [
    ["WD", "WD"],
    ["COM", "COM"],
    ["L", "L"],
    ["TD", "TD"],
    ["LCD", "LCD"],
    ["PD", "PD"],
    // ["INPR", "INPR"],
    // ["HFD", "HFD"],
    // ["LMD", "LMD"],
    // ["LMHF", "LMHF"],
    // ["EMD", "EMD"],
    // ["EMHF", "EMHF"],
    
    // ["LCHF", "LCHF"],
    // ["THF", "THF"],
   
    // ["AB", "AB"],
    // ["DETD", "DETD"],
  ]
  columns: string[][] = [ 

    ["LV_28_OP", "OP"],
    ["LV_28_AL", "AL"],
    ["LV_28_AP", "AP"],
    ["LV_28_IC", "IC"],
    ["LV_28_FD", "FD"],
    ["LV_28_CL", "CL"],

    ["LV_27_OP", "OP"],
    ["LV_27_AL", "AL"],
    ["LV_27_AP", "AP"],
    ["LV_27_IC", "IC"],
    ["LV_27_FD", "FD"],
    ["LV_27_CL", "CL"],

    ["LV_26_OP", "OP"],
    ["LV_26_AL", "AL"],
    ["LV_26_AP", "AP"],
    ["LV_26_IC", "IC"],
    ["LV_26_FD", "FD"],
    ["LV_26_CL", "CL"],

    ["LV_30_OP", "OP"],
    ["LV_30_AL", "AL"],
    ["LV_30_AP", "AP"],
    // ["LV_30_IC", "IC"],
    ["LV_30_FD", "FD"],
    ["LV_30_CL", "CL"],

    ["LV_31_OP", "OP"],
    ["LV_31_AL", "AL"],
    ["LV_31_AP", "AP"],
    // ["LV_31_IC", "IC"],
    ["LV_31_FD", "FD"],
    ["LV_31_CL", "CL"],
  ];

  today = new Date().getFullYear().toString() + "-" + (new Date().getMonth() + 1).toString() + "-" + new Date().getDate().toString();
  current = new Date();
  filterClass: string = "filter-invisible";
  initFilter = true;
  month = this.today;
  isSpinning = false;
  month1;
  empId: number;
  drawerVisible: boolean;
  drawerData: UpdateAttendanceRegister = new UpdateAttendanceRegister();
  drawerTitle: string;
  excelDataList = [];

  constructor(private api: ApiService, private datePipe: DatePipe, private message: NzNotificationService, private cookie: CookieService, private _exportService: ExportService) { }

  ngOnInit() {
    let date = new Date();
    this.startValue1 = (date.getMonth() + 1).toString();

    this.endValue = date;
    // this.getBranch();
    // this.getDepartment();
    this.search(true);
  }

  disabledStartDate = (startValue: Date): boolean => {
    if (!startValue) {
      return false;
    }

    return startValue.getTime() > this.current.getTime();
  };

  disabledEndDate = (endValue: Date): boolean => {
    if (!endValue || !this.startValue) {
      return false;
    }

    return (
      endValue.getTime() < this.startValue.getTime() ||
      endValue.getTime() >= this.current.getTime()
    );
  };

  onStartChange(date: Date): void {
    this.startValue = date;
  }

  onEndChange(date: Date): void {
    this.endValue = date;
  }

  handleStartOpenChange(open: boolean): void {
    if (!open) {
      this.endOpen = true;
    }

    console.log("handleStartOpenChange", open, this.endOpen);
  }

  handleEndOpenChange(open: boolean): void {
    console.log(open);
    this.endOpen = open;
    this.startOpen = open;
  }

  @ViewChild("moduleEndDatePicker", { static: true }) moduleEndDatePickerVar: NzDatePickerComponent;

  moduleStartDateHandle(open: boolean) {
    if (!open) {
      this.endOpen = true;
    }
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  filterQuery: string = "";

  search(reset: boolean = false, exportInExcel: boolean = false) {
    this.filterQuery = "";

    if (reset) {
      this.pageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    if (this.searchText != "") {
      likeQuery = " AND (";

      likeQuery += " EMPLOYEE_NAME like ('%" + this.searchText + "%') OR";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      this.filterQuery += likeQuery.substring(0, likeQuery.length - 3) + ")";
    }

    var monthFilterStr = "";
    if (this.startValue1 != undefined) {
      monthFilterStr = " AND MONTH IN (" + this.startValue1 + ")";
    }


 

    var yearFilterStr = "";
    if (this.endValue != undefined) {
      yearFilterStr = " AND YEAR IN (" + this.datePipe.transform(this.endValue, "yyyy") + ")";
    }

 

    if (exportInExcel) {
      this.exportLoading = true;

      this.api.getEmployeeLeaveRegister(0, 0, this.sortKey, sort, this.filterQuery + monthFilterStr + yearFilterStr ).subscribe(data => {
        if (data['code'] == 200) {
      this.exportLoading = false;
          this.excelDataList = data['data'];
          this.convertInExcel();
        }

      }, err => {
        if (err['ok'] == false)
          this.message.error("Server Not Found", "");
      });

    } else {
      this.loadingRecords = true;

      this.api.getEmployeeLeaveRegister(this.pageIndex, this.pageSize, this.sortKey, sort, this.filterQuery + monthFilterStr + yearFilterStr  ).subscribe((data) => {
        if (data["code"] == 200) {
      this.loadingRecords = false;
          this.totalRecords = data["count"];
          this.dataList = data["data"];
        }

      }, (err) => {
        if (err['ok'] == false)
          this.message.error("Server Not Found", "");
      });
    }
  }

  onKeypressEvent() {
    document.getElementById("searchBtn").focus();
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }

  clearFilter() {
    let date = new Date();
    // this.startValue1 = this.current.getMonth() + 1;
    this.startValue1 = (date.getMonth() + 1).toString();

    this.endValue = date;
    this.BRANCH_ID = [];
    this.DEPARTMENT_ID = [];
    this.search(true);
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
  }

  applyFilter() {
    if ((this.startValue1 != undefined) || (this.endValue != undefined) )
      this.isFilterApplied = "primary";

    else
      this.isFilterApplied = "default";

    this.search(true);
    this.filterClass = "filter-invisible";
  }

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";

    else this.filterClass = "filter-visible";
  }



  BRANCH_ID = [];
  DEPARTMENT_ID = [];
  branch = [];
  department = [];
  orgId = this.cookie.get('orgId');

  getBranch() {
    this.branch = [];

    this.api.getAllBranch(0, 0, 'NAME', 'asc', ' AND STATUS=1 AND ORG_ID=' + this.orgId).subscribe(data => {
      if (data['code'] == 200) {
        this.branch = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  getDepartment() {
    this.department = [];

    this.api.getAllDepartments(0, 0, 'NAME', 'asc', ' AND STATUS=1 AND ORG_ID=' + this.orgId).subscribe(data => {
      if (data['code'] == 200) {
        this.department = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  exportLoading: boolean = false;

  importInExcel() {
    this.search(true, true)
  }

//   convertInExcel() {
//     var arry1 = [];
//     var obj1: any = new Object();
//     var obj2: any = new Object();
 

//     for (var i = 0; i < this.excelDataList.length; i++) {
//       obj1['Month'] = this.getMonthInText(this.excelDataList[i]['MONTH']);
//       obj1['Year'] = this.excelDataList[i]['YEAR'];
//       obj1['ID'] = this.excelDataList[i]['EMP_ID'];
//       // obj1['Code'] = this.excelDataList[i]['EMPLOYEE_CODE'];
//       obj1['Employee Name'] = this.excelDataList[i]['EMPLOYEE_NAME'];
//       obj1['WD'] = this.excelDataList[i]['WORKING'];
//       obj1['COMP'] = this.excelDataList[i]['COMPOFF'];
//       obj1['l'] = this.excelDataList[i]['LEAVE_COUNT'];
//       obj1['TD'] = this.excelDataList[i]['TOTAL_DEDUCTION'];
//       obj1['LCD'] = this.excelDataList[i]['LATECOMING'];
//       obj1['PD'] = this.excelDataList[i]['PAY_DAYS'];


// // obj1['Earned Leave'] = {
// //   ['Working Day']: this.excelDataList[i]['WORKING'],
// //   ['Holiday']: this.excelDataList[i]['HOLIDAY']
// // };

// // obj1["Earned Leave('Working Day')"]= this.excelDataList[i]['WORKING']

// // obj1["Earned Leave"]= obj1['Late Coming'] = this.excelDataList[i]['LATECOMMING']
// obj1['Earned Leave']=["AL"]['AP'] = this.excelDataList[i]['LV_28_AL'],['LV_28_AP'];



//       obj1["Earned Leave('OP')"] = this.excelDataList[i]['LV_28_OP'];
//       obj1["Earned Leave('AL')"] = this.excelDataList[i]['LV_28_AL'];
//       obj1["Earned Leave('AP')"] = this.excelDataList[i]['LV_28_AP'];
//       obj1["Earned Leave('IC')"] = this.excelDataList[i]['LV_28_IC'];
//       obj1["Earned Leave('FD')"] = this.excelDataList[i]['LV_28_FD'];
//       obj1["Earned Leave('CL')"] = this.excelDataList[i]['LV_28_CL'];
     

//       obj1["Casual Leave(OP)"] = this.excelDataList[i]['LV_27_OP'];
//       obj1["Casual Leave(AL)"] = this.excelDataList[i]['LV_27_AL'];
//       obj1["Casual Leave(AP)"] = this.excelDataList[i]['LV_27_AP'];
//       obj1["Casual Leave(IC)"] = this.excelDataList[i]['LV_27_IC'];
//       obj1["Casual Leave(FD)"] = this.excelDataList[i]['LV_27_FD'];
//       obj1["Casual Leave(CL)"] = this.excelDataList[i]['LV_27_CL'];
     
//       obj1['OP'] = this.excelDataList[i]['LV_26_OP'];
//       obj1['AL'] = this.excelDataList[i]['LV_26_AL'];
//       obj1['AP'] = this.excelDataList[i]['LV_26_AP'];
//       obj1['IC'] = this.excelDataList[i]['LV_26_IC'];
//       obj1['FD'] = this.excelDataList[i]['LV_26_FD'];
//       obj1['CL'] = this.excelDataList[i]['LV_26_CL'];
     
//       obj1['OP'] = this.excelDataList[i]['LV_30_OP'];
//       obj1['AL'] = this.excelDataList[i]['LV_30_AL'];
//       obj1['AP'] = this.excelDataList[i]['LV_30_AP'];
//       obj1['FD'] = this.excelDataList[i]['LV_30_FD'];
//       obj1['CL'] = this.excelDataList[i]['LV_30_CL'];
     
//       obj1['OP'] = this.excelDataList[i]['LV_31_OP'];
//       obj1['AL'] = this.excelDataList[i]['LV_31_AL'];
//       obj1['AP'] = this.excelDataList[i]['LV_31_AP'];
//       obj1['FD'] = this.excelDataList[i]['LV_31_FD'];
//       obj1['CL'] = this.excelDataList[i]['LV_31_CL'];
     

//       arry1.push(Object.assign({}, obj1,obj2));

//       if (i == this.excelDataList.length - 1) {
//         this._exportService.exportExcel(arry1, 'Leave Register ' + this.datePipe.transform(new Date(), 'dd-MMM-yy, hh mm ss a'));
//       }
//     }

//   }
fileName = 'LeaveRegisterReport.xlsx';

convertInExcel(){
  let element = document.getElementById('summer');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    var wscols = [];
    var cols_width = 13; // Default cell width
    wscols.push({
      wch: 18
    }); // Set 1stColumn @32 character wide
    for (var i = 0; i < 50; i++) {
      // Increase/Decrease condition_value based on the nmbr of columns you've on your excel sheet
      wscols.push({
        wch: cols_width
      });
    }
    ws['!cols'] = wscols;
    
    ws['!rows'] = [{
        hpx: 0
      }, // "pixels" in row1
      {
        hpx: 0
      }, // "pixels" in row2
      {
        hpx: 20
      } 
    ];
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    setTimeout(() => {
      this.loadingRecords = false;
      /* save to file */
      XLSX.writeFile(wb, this.fileName);
    }, 1000);
}

  getMonthInText(monthInNumber: number) {
    if (monthInNumber === 1)
      return "January";

    else if (monthInNumber === 2)
      return "February";

    else if (monthInNumber === 3)
      return "March";

    else if (monthInNumber === 4)
      return "April";

    else if (monthInNumber === 5)
      return "May";

    else if (monthInNumber === 6)
      return "June";

    else if (monthInNumber === 7)
      return "July";

    else if (monthInNumber === 8)
      return "August";

    else if (monthInNumber === 9)
      return "September";

    else if (monthInNumber === 10)
      return "October";

    else if (monthInNumber === 11)
      return "November";

    else if (monthInNumber === 12)
      return "December";
  }
}