import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root'
})

export class ExportService {
  fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  fileExtension = '.xlsx';

  constructor() { }

  public exportExcel(jsonData: any[], fileName: string): void {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData);
    const wb: XLSX.WorkBook = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    console.log("export to excel" + excelBuffer)
    this.saveExcelFile(excelBuffer, fileName);
  }

  private saveExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: this.fileType });
    FileSaver.saveAs(data, fileName + this.fileExtension);
  }


  exportExcel1(jsonData: any[], fileName: string): void {
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet([]);
    const headers = Object.keys(jsonData[0]);
    XLSX.utils.sheet_add_aoa(ws, [headers], { origin: 'A1' });
    jsonData.forEach((data, rowIndex) => {
      const row: any = [];
      headers.forEach((header, colIndex) => {
        if (header === 'View Bill' && data[header] !== '-') {
          row[colIndex] = {
            f: `HYPERLINK("${data[header].replace('=HYPERLINK("', '').replace('", "Download")', '')}", "View Attachment")`,
          };
        } else {
          row[colIndex] = data[header];
        }
      });
      XLSX.utils.sheet_add_aoa(ws, [row], { origin: { r: rowIndex + 1, c: 0 } });
    });

    // Create workbook and write buffer
    const wb: XLSX.WorkBook = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    // Save to file
    this.saveExcelFile(excelBuffer, fileName);
  }
}
