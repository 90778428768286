import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { leaveadjustment } from 'src/app/Models/leaveadjustment';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';
import * as XLSX from 'xlsx';

// import { ApiService } from '../Service/api.service';

@Component({
  selector: 'app-leave-adjustment-table',
  templateUrl: './leave-adjustment-table.component.html',
  styleUrls: ['./leave-adjustment-table.component.css']
})
export class LeaveAdjustmentTableComponent implements OnInit {
  isSpinning: boolean = false
  formTitle = "Leave Adjustment"
  isVisible = false
  drawerTitle: any
  pageIndex = 1;
  pageSize = 10;
  sortKey: string = "id";
  sortValue: string = "desc";
  drawerdata: leaveadjustment = new leaveadjustment()
  tablehide: boolean
  startbutton: boolean
  filterbutton: boolean
  columns4 = []

  columns2: string[][] = [["EMPLOYEE_NAME", "Employees"]]
  columns: string[][] = [['DATE', 'Date'], ['REMARK', 'Adjustment Remark'], ['USER_NAME', 'User Name'], ['CREATED_MODIFIED_DATE', 'Date1'],]

  searchText: string = "";
  loadingRecords = false
  totalRecords: any
  dataList1 = []
  dataList = []
  filterQuery: string = '';
  userName = this.cookie.get('userName')
  REMARK = ""
  constructor(private api: ApiService, public cookie: CookieService, private message: NzNotificationService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.search(true)
  }
  DATE: string = ""
  search(reset: boolean = false) {
    this.isSpinning = true
    var filter = ""
    this.filterQuery = ''
    if (reset) {
      this.pageIndex = 1;
    }

    this.loadingRecords = true;
    var sort: string;

    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    console.log("search text : " + this.searchText);
    var likeQuery = "";
    if (this.searchText != "") {
      likeQuery = " AND ";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
    }

    if (this.DATE != '') {
      this.filterQuery = " AND DATE ='" + this.datePipe.transform(this.DATE, 'yyyy-MM-dd') + "'";

    }
    // else{
    //   this.filterQuery = " AND DATE =''" ;

    // }

    this.api.getAdjustmentData(this.pageIndex, this.pageSize, this.sortKey, this.sortValue, this.filterQuery + likeQuery).subscribe(data => {
      console.log(data['data']);
      this.loadingRecords = false;
      this.isSpinning = false
      this.totalRecords = data['count'];
      this.dataList1 = data['data'];

    }, err => {
      console.log(err);
    });


  }
  isVisible1 = false
  dataList111 = []
  employess = []

  add11() {

    this.drawerTitle = "Add Leave Adjustment"

    this.isVisible1 = true




    this.api.getAllleaveadjustment(0, 0, this.sortKey, this.sortValue, '').subscribe(data => {

      console.log(data['data']);

      this.loadingRecords = false;

      this.totalRecords = data['count'];

      this.dataList111 = data['data'];

      console.log(this.dataList111);

      for (let i = 0; i < this.dataList111.length; i++) {

        this.employess.push(this.dataList111[i]['EMPLOYEE_NAME'])



      }

      console.log(this.employess);



    }, err => {

      console.log(err);

    });




  }
  add() {
    this.drawerTitle = "Add Leave Adjustment"
    this.isVisible = true
    this.tablehide = true
    this.startbutton = false
    this.filterbutton = true

  }



  editAdjustment(data) {
    this.drawerTitle = "Edit Leave Adjustment"
    this.isVisible = true
    this.tablehide = false
    this.startbutton = true
    this.filterQuery = ''
    this.filterbutton = false
    this.drawerdata = Object.assign({}, data);

    var adjustFilter: any = "";

    adjustFilter = data.ID;
    this.filterQuery += " AND LEAVE_BALANCE_ADJUSTMENT_MASTER_ID = " + adjustFilter;

    this.api.getLeaveadjustmentDetails(0, 0, this.sortKey, this.sortValue, this.filterQuery).subscribe(data => {
      console.log(data['data']);
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];
      console.log(this.dataList);


      this.api.getLeaveData(0, 0, '', '', 'AND STATUS=1').subscribe(data2 => {
        if (data2['code'] == 200) {
          this.allemps = data2['data']
          this.columns4 = data2['data']


          // for (let h = 0; h < this.dataList.length; h++) {
          // this.dataList[0].split(",")
          var result: any = Object.keys(this.dataList[0]).map((key) => [(key), this.dataList[0][key]]);

          for (let j = 0; j < result.length; j++) {
            //  if( this.dataList[i].EMPLOYEE_ID == this.allemps[j]['ID'])
            //  this.dataList['NAME'] =(this.allemps[j]['NAME'])
            var s = result[j][0].split('_')

            for (let i = 0; i < this.allemps.length; i++) {
              console.log(s[0]);
              if (this.allemps[i]['ID'] == s[0].replace('LV', '')) {
                var a = result[j][0].split('_')
                if ('C' == a[1])
                  this.columns4[i]['KEY1'] = result[j][0];
                if ('S' == a[1])
                  this.columns4[i]['KEY3'] = result[j][0];
                if ('N' == a[1])
                  this.columns4[i]['KEY2'] = result[j][0];
                console.log(this.columns4[i])
              }

            }
            if (j + 1 == result.length) {

            }
          }
        }

      }, err => {
        if (err['ok'] == false)
          this.message.error("Server Not Found", "");
      });

    }, err => {
      console.log(err);
    });
  }

  drawerClose() {
    this.isVisible = false
    this.search(true)
    this.tablehide = undefined
    this.startbutton = undefined
    this.filterbutton = undefined

  }

  get closeCallBack() {
    return this.drawerClose.bind(this)
  }

  drawerClose11() {
    this.isVisible1 = false
    this.search(true)
    this.tablehide = undefined
    this.startbutton = undefined
    this.filterbutton = undefined

  }

  get closeCallBack11() {
    return this.drawerClose11.bind(this)
  }

  edit(data) {
    console.log(data);

    this.drawerTitle = "Update Leave Adjustment"
    this.isVisible = true

    // this.api.getLeaveAdjustmentMappingData
  }

  sort(sort: any): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    if (this.sortValue == "descend") {
      this.sortValue = 'desc';
    } else {
      this.sortValue = 'asc'
    }
    console.log(this.sortValue + "kk");
    this.search(true);
  }
  isApkVersionModalVisible = false;
  isApkVersionModalConfirmLoading = false;
  apkVersionModalTitle: string = "";

  handleApkVersionModalCancel(): void {
    this.isApkVersionModalVisible = false;
    this.isApkVersionModalConfirmLoading = false;


  }
  allemps = []

  showApkVersionModal(data: any): void {
    this.filterQuery = ''
    this.isApkVersionModalVisible = true;
    this.apkVersionModalTitle = "Leave Adjustment Details";

    this.filterQuery += " AND LEAVE_BALANCE_ADJUSTMENT_MASTER_ID = " + data.ID;
    this.api.getLeaveadjustmentDetails(0, 0, this.sortKey, this.sortValue, this.filterQuery).subscribe(data => {
      console.log(data['data']);
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];
      console.log(this.dataList);
      this.api.getLeaveData(0, 0, '', '', 'AND STATUS=1').subscribe(data2 => {
        if (data2['code'] == 200) {
          this.allemps = data2['data']
          this.columns = data2['data']
          // for (let h = 0; h < this.dataList.length; h++) {
          // this.dataList[0].split(",")
          var result: any = Object.keys(this.dataList[0]).map((key) => [(key), this.dataList[0][key]]);
          for (let j = 0; j < result.length; j++) {
            //  if( this.dataList[i].EMPLOYEE_ID == this.allemps[j]['ID'])
            //  this.dataList['NAME'] =(this.allemps[j]['NAME'])
            var s = result[j][0].split('_')

            for (let i = 0; i < this.allemps.length; i++) {
              console.log(s[0]);
              if (this.allemps[i]['ID'] == s[0].replace('LV', '')) {
                var a = result[j][0].split('_')
                if ('C' == a[1])
                  this.columns[i]['KEY1'] = result[j][0];
                if ('S' == a[1])
                  this.columns[i]['KEY3'] = result[j][0];
                if ('N' == a[1])
                  this.columns[i]['KEY2'] = result[j][0];
                console.log(this.columns[i])
              }

            }
            if (j + 1 == result.length) {

            }
          }
        }
      }, err => {

      });
    }, err => {
      console.log(err);
    });
  }

  filterClass: string = "filter-invisible";
  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";

  }

  applyFilter() {

    if (this.DATE != null && this.DATE != undefined) {
      this.search(true);
    }

    this.filterClass = "filter-invisible";


  }
  clearFilter() {
    this.filterClass = "filter-invisible";
    this.DATE = ""
    this.search(true);

  }


  // allemps = []
  fileName = 'LeaveAdjustment.xlsx';
  // convertInExcel11() {
  //   console.log("uvtech")
  //   let element = document.getElementById('printModal');
  //   const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
  //   var wscols = [];
  //   var cols_width = 13; // Default cell width
  //   wscols.push({
  //     wch: 45
  //   }); // Set 1stColumn @32 character wide
  //   for (var i = 0; i < 60; i++) {
  //     // Increase/Decrease condition_value based on the number of columns you've on your excel sheet
  //     wscols.push({
  //       wch: cols_width
  //     });
  //   }

  //   ws['!cols'] = wscols;
  //   ws['!rows'] = [{
  //     hpx: 0
  //   }, // "pixels" in row1
  //   {
  //     hpx: 0
  //   }, //"pixels" in row1
  //   {
  //     hpx: 20
  //   }
  //   ];
  //   /* generate workbook and add the worksheet */
  //   const wb: XLSX.WorkBook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  //   setTimeout(() => {
  //     this.loadingRecords = false;
  //     XLSX.writeFile(wb, this.fileName);
  //   }, 1000);
  // }
  exportLoading: boolean = false

  importInExcel() {
    this.exportLoading = true;
    // this.filterQuery += " AND LEAVE_BALANCE_ADJUSTMENT_MASTER_ID = " + this.ID;
    this.api.getLeaveadjustmentDetails(0, 0, this.sortKey, this.sortValue, this.filterQuery).subscribe(data => {
      console.log(data['data']);
      if (data['code'] == 200) {
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];
        console.log(this.dataList);
        this.api.getLeaveData(0, 0, '', '', 'AND STATUS=1').subscribe(data2 => {
          if (data2['code'] == 200) {
            this.allemps = data2['data']
            this.exportLoading = false;
            this.columns = data2['data']
            this.convertInExcel11();
            // for (let h = 0; h < this.dataList.length; h++) {
            // this.dataList[0].split(",")
            var result: any = Object.keys(this.dataList[0]).map((key) => [(key), this.dataList[0][key]]);
            for (let j = 0; j < result.length; j++) {
              //  if( this.dataList[i].EMPLOYEE_ID == this.allemps[j]['ID'])
              //  this.dataList['NAME'] =(this.allemps[j]['NAME'])
              var s = result[j][0].split('_')

              for (let i = 0; i < this.allemps.length; i++) {
                console.log(s[0]);
                if (this.allemps[i]['ID'] == s[0].replace('LV', '')) {
                  var a = result[j][0].split('_')
                  if ('C' == a[1])
                    this.columns[i]['KEY1'] = result[j][0];
                  if ('S' == a[1])
                    this.columns[i]['KEY3'] = result[j][0];
                  if ('N' == a[1])
                    this.columns[i]['KEY2'] = result[j][0];
                  console.log(this.columns[i])
                }

              }
              if (j + 1 == result.length) {

              }
            }
          }
        }, err => {

        });
      }

    }, (err) => {
      // if (this.api.checkOnlineStatus()) {
      //   console.log(err);
      //   this.message.error("The server's internet connection is down. Please contact the EDP department for help.", '');
      // } else {
      this.message.error("Cannot perform operation due to unstable Internet connection. ", "");
      // }
    });
  }
//   convertInExcel11() {
//     console.log("uvtech");
//     let element = document.getElementById('printModal');
//     const rows = Array.from(element.getElementsByTagName('tr'));
//     const data = rows.map(row => {
//         const cells = Array.from(row.getElementsByTagName('td'));
//         return cells.map(cell => {
//             let cellValue = cell.innerText.trim();
//             // Convert numeric strings to numbers
//             if (!isNaN(cellValue as any) && cellValue !== '') {
//                 return parseFloat(cellValue);
//             }
//             return cellValue;
//         });
//     });

//     // Create a new worksheet from the data
//     const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(data);

//     // Define column widths
//     var wscols = [{ wch: 45 }]; // Set 1st column to 45 characters wide
//     var cols_width = 13; // Default cell width
//     for (var i = 1; i < 60; i++) { // Adjust the loop to start from 1 to avoid overriding the first column
//         wscols.push({ wch: cols_width });
//     }

//     ws['!cols'] = wscols;

//     // Set row heights if needed
//     ws['!rows'] = [
//         { hpx: 0 }, // "pixels" in row1
//         { hpx: 0 }, // "pixels" in row2
//         { hpx: 20 } // "pixels" in row3
//     ];

//     // Generate workbook and add the worksheet
//     const wb: XLSX.WorkBook = XLSX.utils.book_new();
//     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

//     setTimeout(() => {
//         this.loadingRecords = false;
//         XLSX.writeFile(wb, this.fileName);
//     }, 1000);
// }

convertInExcel11() {
  console.log("uvtech");
  let element = document.getElementById('printModal');
  const rows = Array.from(element.getElementsByTagName('tr'));
  const data = rows.map((row, rowIndex) => {
      if (rowIndex === 0) { // Handle header row separately
          const headers = Array.from(row.getElementsByTagName('th'));
          return headers.map(header => header.innerText.trim());
      } else { // Handle data rows
          const cells = Array.from(row.getElementsByTagName('td'));
          return cells.map(cell => {
              let cellValue = cell.innerText.trim();
              // Convert numeric strings to numbers, but keep strings like "0.5" as they are
              if (!isNaN(cellValue as any) && cellValue !== '' && !/^0\.\d+$/.test(cellValue)) {
                  return parseFloat(cellValue);
              }
              return cellValue;
          });
      }
  });

  // Create a new worksheet from the data
  const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(data);

  // Define column widths
  var wscols = [{ wch: 45 }]; // Set 1st column to 45 characters wide
  var cols_width = 13; // Default cell width
  for (var i = 1; i < 60; i++) { // Adjust the loop to start from 1 to avoid overriding the first column
      wscols.push({ wch: cols_width });
  }

  ws['!cols'] = wscols;

  // Set row heights if needed
  ws['!rows'] = [
      { hpx: 0 }, // "pixels" in row1
      { hpx: 0 }, // "pixels" in row2
      { hpx: 20 } // "pixels" in row3
  ];

  // Generate workbook and add the worksheet
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  setTimeout(() => {
      this.loadingRecords = false;
      XLSX.writeFile(wb, this.fileName);
  }, 1000);
}
}
